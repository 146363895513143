import Vue from 'vue';

import address from './address';
import companies from './companies';
import orders from './modules/orders';
import drafts from './modules/drafts';
import transactions from './modules/transactions';

export default {
    namespaced: true,

    modules: {
        address,
        companies,
        orders,
        drafts,
        transactions,
    },

    state: {
        NATURAL_ENTITY: 'natural-entity',
        LEGAL_ENTITY: 'legal-entity',

        MODULE_ORDERS: 'module-orders',
        MODULE_DRAFTS: 'module-drafts',
        MODULE_TRANSACTIONS: 'module-transactions',

        list: [],
        listPagination: {},
        listFilter: {
            page: null,
            limit: 30,
            name: null,
            surname: null,
            patronymic: null,
            fullName: null,
            email: null,
            phone: null,
            company_id: null,
            manager_id: null,
            sort: 'new',
            direction: 'desc',
        },

        item: {},
        bitrixItem: {},

        loadingGetList: false,
        loadingGetItem: false,
        loadingGetBitrixItem: false,
        loadingSaveItem: false,
        loadingDeleteItem: false,
        loadingGetLogs: false,
        loadingImport: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        isFilterNotEmpty(state, getters, rootState) {
            return !!Object.keys(getters.listActiveFilter).filter(
                (key) => !rootState.constant.INACTIVE_FILTERING_KEYS.concat(state.INACTIVE_FILTERING_KEYS).includes(key),
            ).length;
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_BITRIX_ITEM(state, bitrixItem) {
            state.bitrixItem = bitrixItem;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        UPDATE_LIST_FILTER(state, newFilterlist) {
            Object.entries(newFilterlist).forEach(([key, value]) => Vue.set(state.listFilter, key, value));
            Vue.set(state.listFilter, 'page', newFilterlist.page || 1);
        },

        RESET_LIST(state) {
            state.list = [];
        },

        RESET_ITEM(state) {
            state.item = {};
        },

        RESET_BITRIX_ITEM(state) {
            state.bitrixItem = {};
        },

        RESET_STORE(state) {
            state.listFilter = {
                page: null,
                limit: null,
                name: null,
                surname: null,
                patronymic: null,
                fullName: null,
                email: null,
                phone: null,
                company_id: null,
                manager_id: null,
                sort: 'new',
                direction: 'desc',
            };
        },
    },

    actions: {
        GET_LIST: async ({ state, rootState, rootGetters, getters, commit }, payload = { filters: {} }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let path = `/api/clients`;
                if (rootGetters.hasTypographyAccess) {
                    path = `/api/typographies/${rootGetters.currentTypography.id}/clients`;
                }

                let resp = await $axios.get(path, {
                    params: Object.keys(payload.filters).length ? payload.filters : getters.listActiveFilter,
                });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ITEM: async ({ state, rootState, rootGetters, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let path = `/api/clients/${id}`;
                if (rootGetters.hasTypographyAccess) {
                    path = `/api/typographies/${rootGetters.currentTypography.id}/clients/${id}`;
                }

                let resp = await $axios.get(path);

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        GET_BITRIX_ITEM: async ({ state, rootState, rootGetters, getters, commit }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetBitrixItem', status: true });

                let resp = await $axios.get(`/api/typographies/${rootGetters.currentTypography.id}/clients/${id}/bitrix`);

                commit('SET_BITRIX_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetBitrixItem', status: false });
            }
        },

        SAVE_ITEM: async ({ state, rootState, rootGetters, commit, dispatch }, { formData, without_fetch }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters.currentTypography.id}/clients`
                    : `/api/clients`;

                let resp = await $axios.post(path, formData);

                if (!without_fetch) {
                    formData.id && Object.keys(state.item).length ? dispatch('GET_ITEM', formData.id) : dispatch('GET_LIST');
                }

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, id) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let path = `/api/clients/${id}`;
                if (rootGetters.hasTypographyAccess) {
                    path = `/api/typographies/${rootGetters.currentTypography.id}/clients/${id}`;
                }

                let resp = await $axios.delete(path);
                dispatch('GET_LIST');

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },

        START_IMPORT: async ({ state, rootState, rootGetters, getters, commit, dispatch }, { id, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.post(`/api/typographies/${rootGetters.currentTypography.id}/clients/import`, formData, {
                    headers: {
                        'Currency-Code': rootState.currency.DEFAULT_ORDER_CURRENCY,
                    },
                });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        RUN_IMPORT: async ({ state, rootState, rootGetters, getters, commit, dispatch }, { id, importId }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingImport',
                    status: true,
                });

                let resp = await $axios.post(
                    `/api/typographies/${rootGetters.currentTypography.id}/clients/import/${importId}/run`,
                    {},
                    {
                        headers: {
                            'Currency-Code': rootState.currency.DEFAULT_ORDER_CURRENCY,
                        },
                    },
                );

                setTimeout(() => {
                    dispatch('GET_LIST');
                }, 8000);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                setTimeout(() => {
                    commit('SET_LOADING_STATUS', {
                        value_key: 'loadingImport',
                        status: false,
                    });
                }, 9000);
            }
        },

        CLIENT_BALANCE: async ({ state, rootGetters, commit, dispatch }, { clientId, formData }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let resp = await $axios.post(
                    `/api/typographies/${rootGetters.currentTypography.id}/clients/${clientId}/pay`,
                    formData,
                );

                dispatch('GET_ITEM', clientId);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },
    },
};
