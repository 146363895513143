<template>
    <div class="lmtd-txt" v-if="hasFull">
        <div class="lmtd-txt__body" :class="{ 'druk-is-row': isRow }">
            <div
                v-html="text"
                class="lmtd-txt__area"
                :class="{ '_ck-editor-content': isCKEditorContent, 'druk-has-gap': hasButton && !isRow }"></div>

            <div v-if="hasButton" class="lmtd-txt__link" :class="{ 'druk-is-distant': !isRow }">
                <druk-link
                    :label="isShown ? $t('common.read_more_close') : $t('common.read_more')"
                    :icon="isShown ? 'minus' : 'plus'"
                    :isActive="isShown"
                    @click="isShown = !isShown" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'form-limited-text',

        props: {
            value: String,
            limit: Number,
            isRow: Boolean,
            isCKEditorContent: Boolean,
        },

        data() {
            return {
                isShown: false,
            };
        },

        computed: {
            hasFull() {
                return this.value;
            },

            hasButton() {
                return this.value.length > this.limit;
            },

            text() {
                return this.isShown
                    ? this.value
                    : this.value.length > this.limit
                      ? this.value.substring(0, this.limit).concat('...')
                      : this.value;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .lmtd-txt {
        display: block;
        width: 100%;
        &__body {
            &.druk-is-row {
                display: inline-flex;
                flex-wrap: wrap;
                align-items: center;
            }
        }
        &__area,
        &__link {
            vertical-align: middle;
        }
        &__area {
            display: inline-block;
            word-break: break-word;
            white-space: pre-wrap;
            &:not(._ck-editor-content) {
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.5px;
                font-weight: 500;
            }
            &._ck-editor-content {
                font-size: initial;
                font-weight: initial;
                letter-spacing: initial;
                line-height: initial;
            }
            &.druk-has-button {
                margin-right: var(--druk-gap-s);
            }
        }
        &__link {
            display: block;
            &.druk-is-distant {
                margin-top: var(--druk-gap-xs);
            }
        }
    }
</style>
