var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"druk-c-tabs"},[_c('div',{ref:"wrapper",staticClass:"druk-c-tabs__wrapper",class:{ 'druk-is-secondary': _vm.isSecondary, 'druk-has-no-scroll': _vm.hasNoScroll }},[_c('div',{staticClass:"druk-c-tabs__body",class:{ 'druk-is-section-tabs': _vm.isSectionTabs, 'druk-is-secondary': _vm.isSecondary, 'druk-is-centered': _vm.isCentered }},_vm._l((_vm.tabs),function(tab,index){return _c('div',{key:index,staticClass:"druk-c-tabs__tab",class:{
                    'druk-is-active': _vm.activeTab === index,
                    'druk-is-section-tabs': _vm.isSectionTabs,
                    'druk-is-secondary': _vm.isSecondary,
                },on:{"click":function($event){return _vm.onTab(index)}}},[_c('div',{staticClass:"druk-c-tabs__tab-state",class:{ 'druk-is-active': _vm.activeTab === index, 'druk-is-section-tabs': _vm.isSectionTabs }}),_c('div',{staticClass:"druk-c-tabs__tab-content",class:{ 'druk-has-icon': tab.icon }},[(!!tab.icon)?_c('div',{staticClass:"druk-c-tabs__tab-icon"},[_c('druk-icon',{attrs:{"name":tab.icon,"size":'m',"color":_vm.activeTab === index ? 'primary' : 'on-surface-variant'}})],1):_vm._e(),_c('div',{staticClass:"druk-c-tabs__tab-label druk-u-text-title-s",class:{
                            'druk-is-enlarged': _vm.hasEnlargedLabels || _vm.isSectionTabs,
                        },attrs:{"id":`tab-label-${index}`}},[_c('div',{staticClass:"druk-c-tabs__tab-label-text"},[_vm._v(" "+_vm._s(tab[_vm.tabLabel])+" ")]),(tab.hasBadge)?_c('div',{staticClass:"druk-c-tabs__tab-badge"},[_c('druk-badge')],1):_vm._e()])])])}),0),_c('div',{staticClass:"druk-c-tabs__rail"},[_c('div',{staticClass:"druk-c-tabs__slider",class:{ 'druk-is-secondary': _vm.isSecondary, 'druk-is-initialized': _vm.isInitialized },style:({ width: _vm.sliderConfig.width, left: _vm.sliderConfig.left })})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }