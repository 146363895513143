import Vue from 'vue';

import axios from 'axios';

const CancelToken = axios.CancelToken;

let cancel;

export default {
    namespaced: true,

    state: {
        PRICE_CHANGERS: [
            {
                id: 0,
                translation_alias: 'create_order.price_edit.discount',
            },

            {
                id: 1,
                translation_alias: 'create_order.price_edit.markup',
            },

            {
                id: 2,
                translation_alias: 'create_order.price_edit.fixed_price',
            },
        ],

        DISCOUNT_CHANGER_ID: 0,
        MARKUP_CHANGER_ID: 1,
        FIXED_PRICE_CHANGER_ID: 2,

        list: [],

        preCalculation: {},

        loadingGetPreCalculation: false,
    },

    getters: {
        urgentProducts(state, getters, rootState) {
            return state.list.filter((item) => item.calculationData.principle.value === rootState.products.PRINCIPLE_KEY_FASTEST);
        },

        warehouseList(state) {
            return state.list.filter((item) => Object.keys(item.warehouseData).length);
        },

        warehouseMap(state, getters) {
            const map = getters.warehouseList.map((item) => ({
                kind_id: item.productData.kind.id,
                count: $fn.toFloat(item.calculationData.parameters.count.count),
            }));

            const groupedMap = $fn.groupBy(map, 'kind_id');

            return Object.entries(groupedMap).reduce((acc, curr) => {
                const key = [...curr].shift();
                const values = [...curr].pop();

                values.forEach((value) => (acc[key] ? (acc[key] += value.count) : (acc[key] = value.count)));

                return acc;
            }, {});
        },

        hasOnlyUrgentProducts(state, getters) {
            return getters.hasProducts && getters.urgentProducts.length === state.list.length;
        },

        hasUrgentProducts(state, getters) {
            return getters.hasProducts && getters.urgentProducts.length && getters.urgentProducts.length !== state.list.length;
        },

        hasNoUrgentProducts(state, getters) {
            return !(getters.hasOnlyUrgentProducts || getters.hasUrgentProducts);
        },

        hasProducts(state) {
            return !!state.list.length;
        },

        hasOnlyWarehouseProducts(state, getters) {
            return !!(state.list.length === getters.warehouseList.length && getters.hasProducts);
        },
    },

    actions: {
        GET_LIST: async () => {},

        GET_PRE_CALCULATION: async ({ rootGetters, commit }, { client_id, client_address_id, products }) => {
            try {
                if (cancel !== undefined) cancel();

                commit('SET_LOADING_STATUS', { value_key: 'loadingGetPreCalculation', status: true });

                const path = `/api/typographies/${rootGetters[`currentTypography`].id}/orders/calculate`;

                let resp = await axios.post(
                    path,

                    {
                        client_id,
                        client_address_id,
                        products,
                    },

                    {
                        cancelToken: new CancelToken(function executor(c) {
                            cancel = c;
                        }),
                    },
                );

                commit('SET_PRE_CALCULATION', resp?.single || {});

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetPreCalculation', status: false });
            }
        },

        DELETE_LIST: async () => {},
    },

    mutations: {
        SET_PRE_CALCULATION(state, preCalculation) {
            state.preCalculation = preCalculation;
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            Vue.set(state, value_key, status);
        },

        ADD_TO_LIST(state, item) {
            state.list.push($fn.getDeepClone(item));
        },

        CLONE_TO_LIST(state, { index, data }) {
            const clone = $fn.getDeepClone({
                ...state.list[index],
                ...data,
            });

            state.list.splice(state.list.length, 0, clone);
        },

        UPDATE_LIST_ITEM(state, { index, data }) {
            const item = $fn.getDeepClone({
                ...state.list[index],
                ...data,
            });

            Vue.set(state.list, index, item);
        },

        DELETE_FROM_LIST(state, index) {
            Vue.delete(state.list, index);
        },

        RESET_LIST(state) {
            state.list = [];
        },

        RESET_PRE_CALCULATION(state) {
            state.preCalculation = {};
        },
    },
};
