<template>
    <div
        class="druk-c-icon"
        :class="{
            [`druk-c-icon--${size}`]: size,
            'druk-is-disabled': isDisabled,
            'druk-is-inline': isInline,
        }"
        @click="$emit('click')">
        <font-awesome-icon
            :icon="`fa-${variant} fa-${name}`"
            :style="{
                '--icon-color': iconColor,
                '--fa-secondary-opacity': 0.38,
                '--fa-animation-duration': animation.duration,
            }"
            :spin="hasSpin ? true : false" />

        <div v-if="hasBadge" class="druk-c-icon__badge druk-u-text-label-m">
            {{ badge }}
        </div>
    </div>
</template>

<script>
    export default {
        name: 'druk-icon',

        props: {
            name: {
                type: String,
                default: 'd',
            },
            variant: {
                type: String,
                default: 'regular',
            },
            size: {
                type: String,
                default: 's',
            },
            animation: {
                type: Object,
                default: () => {
                    return { duration: '2s' };
                },
            },
            color: {
                type: String,
                default: 'primary',
            },

            badge: [String, Number],

            hasSpin: Boolean,

            isInline: Boolean,
            isDisabled: [Boolean, Number],
        },

        data() {
            return {
                DISABLED_COLOR: 'on-surface',
            };
        },

        computed: {
            iconColor() {
                return this.isDisabled ? this.$material[this.DISABLED_COLOR] : this.$material[this.color] || this.color;
            },

            hasBadge() {
                return this.badge !== undefined && this.badge !== null;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-icon {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px;
        &--xxs {
            width: 16px;
            height: 16px;
        }
        &--xs {
            width: 18px;
            height: 18px;
        }
        &--s {
            width: 20px;
            height: 20px;
        }
        &--m {
            width: 24px;
            height: 24px;
        }
        &--lg {
            width: 40px;
            height: 40px;
        }
        &--xl {
            width: 48px;
            height: 48px;
        }
        &--full {
            width: 100%;
            height: 100%;
        }
        &.druk-is-inline {
            display: inline-flex;
            vertical-align: text-top;
        }
        svg {
            --color: --icon-color;
            width: 100%;
            height: 100%;
            color: #{var(--icon-color)};
        }
        &__badge {
            position: absolute;
            left: 100%;
            color: var(--druk-on-surface-variant);
        }
    }
</style>
