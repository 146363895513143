<template>
    <div class="druk-c-chip" :class="{ 'druk-is-controll': isControll, 'druk-is-inline': isInline }" @click="onClick">
        <div v-bind:style="style" class="druk-c-chip__wrapper">
            <div v-if="isControll && !isNeutral" class="druk-c-chip__icon">
                <druk-icon v-bind="iconStyles" />
            </div>

            <div class="druk-c-chip__label">{{ label }}</div>

            <div v-if="hint" class="druk-c-chip__hint">
                <druk-hint :tooltip="hint" />
            </div>

            <div v-if="isClearable" class="druk-c-chip__trail" @click="$emit('clear')">
                <druk-icon :name="'xmark'" :color="autoColor || color" />
            </div>

            <div v-if="isControll" v-bind:style="stateStyle" class="druk-c-chip__state"></div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

    export default {
        name: 'druk-chip',

        props: {
            index: Number, // @Explanation: You can pass an index for looped chips to activate auto-color mode
            label: [String, Number],

            icon: {
                type: [String, Object],
                default: 'pen',
            },

            hint: [String, Number, Object],

            color: {
                type: String,
                default: 'primary',
                // @Explanation: Possible values
                // primary, secondary, tertiary, azul, water-blue,
                // topaz, dusk, iris, rouge, rose-red, pale-magenta,
                // warm-pink, guardsman-red, scarlet, coral-red, gamboge,
                // sunglow, candlelight, dark-aqua, shamrock-green, atlantis
            },

            isControll: Boolean,
            isClearable: Boolean,
            isInline: Boolean,
            isNeutral: [Boolean, Number],
            hasDoubleColorMode: Boolean,
        },

        computed: {
            ...mapState({
                SYSTEM_ALT_COLOR_MAP: (state) => state.constant.SYSTEM_ALT_COLOR_MAP,
            }),

            style() {
                return {
                    border: `1px solid var(--druk-chips-${this.isNeutral ? 'neutral' : this.autoColor || this.color})`,
                    color: `var(--druk-chips-on-${this.isNeutral ? 'neutral' : this.autoColor || this.color}-container)`,
                    'background-color': `var(--druk-chips-${this.isNeutral ? 'neutral' : this.autoColor || this.color}-container)`,
                };
            },

            stateStyle() {
                return {
                    'background-color': `var(--druk-chips-on-${this.isNeutral ? 'neutral' : this.autoColor || this.color}-container)`,
                };
            },

            autoColor() {
                if (!this.hasAutoColorMode) return null;

                let colorIndex = this.hasDoubleColorMode ? +(this.index % 2) : this.index;

                while (colorIndex >= this.SYSTEM_ALT_COLOR_MAP.length) colorIndex -= this.SYSTEM_ALT_COLOR_MAP.length;
                return this.SYSTEM_ALT_COLOR_MAP[colorIndex];
            },

            iconStyles() {
                return typeof this.icon === 'string'
                    ? {
                          name: this.icon,
                          color: this.color || this.autoColor,
                      }
                    : this.icon;
            },

            hasAutoColorMode() {
                return this.index !== null && this.index !== undefined;
            },
        },

        methods: {
            onClick(e) {
                if (!e.target.closest('.druk-c-chip__trail')) this.$emit('click');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-chip {
        pointer-events: none;
        &:hover &__state {
            opacity: 0.08;
        }
        &:focus &__state,
        &:active &__state {
            opacity: 0.12;
        }
        &:hover &__state,
        &:focus &__state,
        &:active &__state {
            transition-duration: var(--druk-duration-medium-2);
            transition-timing-function: var(--druk-easing-emphasized-decelerate);
        }
        &.druk-is-controll {
            cursor: pointer;
            pointer-events: all;
        }
        &.druk-is-inline {
            display: inline;
        }
        &__wrapper {
            display: flex;
            align-items: center;
            position: relative;
            padding: var(--druk-gap-s) var(--druk-gap-m);
            max-height: initial;
            border-radius: 8px;
        }
        &__icon {
            margin: var(--druk-n-gap-xs) var(--druk-gap-xs-a) var(--druk-n-gap-xs) 0;
        }
        &__label {
            font-size: 14px;
            line-height: 1;
            letter-spacing: 0.1px;
            font-weight: 500;
        }
        &__hint {
            display: inline-block;
            margin-left: var(--druk-gap-step);
        }
        &__trail {
            margin: var(--druk-n-gap-xs) 0 var(--druk-n-gap-xs) var(--druk-gap-xs-a);
        }
        &__state {
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition-property: opacity;
            transition-duration: var(--druk-duration-short-4);
            transition-timing-function: var(--druk-easing-emphasized-accelerate);
        }
    }
</style>
