<template>
    <div
        class="druk-c-table"
        :class="{
            'druk-has-scroll': hasScroll,
            'druk-is-scrolled': isScrolled,
            'druk-has-card-adaptation': hasCardAdaptation,
            'druk-has-modal-styles': isModalMode,
        }">
        <div class="druk-c-table__container">
            <div
                class="druk-c-table__wrapper"
                :class="{ [`druk-l-surface-${surface}`]: surface, 'druk-has-no-pagination': !hasPagination }">
                <div ref="body" class="druk-c-table__body" :class="{ 'druk-has-loader': hasLoadingList }" @scroll="onScroll">
                    <table ref="table" :class="{ 'druk-has-card-adaptation': hasCardAdaptation }">
                        <thead v-if="!isWithoutHeader" class="druk-c-table__header">
                            <tr>
                                <th
                                    v-for="(header, index) in headers"
                                    v-show="!header.isHidden"
                                    :id="header.isSticky ? 'sticky' : false"
                                    :key="index"
                                    class="druk-c-table__header-item"
                                    :class="{
                                        'druk-u-ta-center': header.isCentered,
                                        'druk-has-actions': header.hasActions,
                                        'druk-is-reduced': !header.text,
                                    }">
                                    <div class="druk-c-table__header-data">
                                        <div class="druk-c-table__header-text">{{ header.text }}</div>

                                        <div
                                            v-if="header.hasSort"
                                            class="druk-c-table__header-sort"
                                            :class="{
                                                'druk-is-picked': header.key === sortKey,
                                                'druk-has-asc-direction': direction === DIRECTION_ASC_KEY,
                                                'druk-has-desc-direction': direction === DIRECTION_DESC_KEY,
                                            }"
                                            @click="updateDirection(header.key)">
                                            <druk-icon
                                                class="druk-c-table__header-sort-icon druk-is-default"
                                                :name="'sort'"
                                                :color="'on-surface-variant'" />

                                            <druk-icon
                                                class="druk-c-table__header-sort-icon druk-is-desc"
                                                :name="'sort-up'"
                                                :color="'on-surface-variant'" />

                                            <druk-icon
                                                class="druk-c-table__header-sort-icon druk-is-asc"
                                                :name="'sort-down'"
                                                :color="'on-surface-variant'" />
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>

                        <template v-for="(group, gIndex) in groups">
                            <template v-if="!hasDefaultGrouping && (activeGrouping.key || activeGrouping.title)">
                                <thead
                                    :key="`group-title-${gIndex}`"
                                    class="druk-c-table__group-title"
                                    :class="{ 'druk-has-payload': activeGrouping.payload, 'druk-has-no-header': isWithoutHeader }">
                                    <tr>
                                        <th
                                            v-for="(header, hIndex) in headers"
                                            v-show="hIndex === 0"
                                            :id="header.isSticky ? 'sticky' : false"
                                            :key="hIndex"
                                            :colspan="header.colspan">
                                            <span v-if="activeGrouping.title">{{ activeGrouping.title }}</span>

                                            <template v-else-if="activeGrouping.key">
                                                <component
                                                    :is="activeGrouping.link ? 'druk-link' : 'span'"
                                                    :label="getGroupTitle(group)"
                                                    :path="activeGrouping.link ? getGroupPath(group) : false"
                                                    :isBlank="true"
                                                    >{{ getGroupTitle(group) }}</component
                                                >
                                            </template>

                                            <component
                                                v-if="activeGrouping.payload"
                                                :is="activeGrouping.payload.base || 'span'"
                                                class="druk-c-table__group-payload"
                                                :class="{
                                                    [activeGrouping.payload.class]: activeGrouping.payload.class,
                                                    [`${activeGrouping.payload.class}--${getGroupPayloadValue(group, 'relatedClass')}`]:
                                                        activeGrouping.payload.relatedClass,
                                                }"
                                                >{{ $t(getGroupPayloadValue(group)) }}</component
                                            >
                                        </th>
                                    </tr>
                                </thead>
                            </template>

                            <slot :name="`rows-${group.id}`"></slot>
                        </template>
                    </table>

                    <div v-if="hasNoItems" class="druk-c-table__prompt">
                        <druk-support>{{ $t('common.table.empty') }}</druk-support>
                    </div>

                    <div ref="loader" class="druk-c-table__loader druk-u-text-body-m druk-l-shape-m druk-l-elevation-2">
                        <druk-loader :size="'s'" :label="$t('common.data_loading')" />
                    </div>
                </div>

                <div
                    v-show="hasScroll && !hasLoadingList && !isModalMode"
                    class="druk-c-table__scroll-layout"
                    :class="{ 'druk-has-loader': hasLoadingList }"
                    :style="{ bottom: `${scrollPosition}px` }">
                    <div ref="scroll" class="druk-c-table__scroll" :style="{ width: bodyWidth ? `${bodyWidth}px` : `100%` }">
                        <span :style="{ width: `${tableWidth}px` }"></span>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="hasPagination" class="druk-c-table__pagination">
            <druk-pagination
                :pagination="pagination"
                :list="items"
                :hasLoadingList="hasLoadingList"
                @changeLimit="$emit('changeLimit', $event)"
                @changePage="$emit('changePage', $event)" />
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';

    import DrukPagination from '@components/druk/DrukPagination';

    export default {
        name: 'druk-table',

        components: {
            DrukPagination,
        },

        props: {
            // @Explanation: Main data
            headers: Array,
            items: Array,

            pagination: {
                type: Object,
                default: () => {
                    return {};
                },
            },

            // @Explanation: Filtering
            sortKey: String,

            // @Explanation: Grouping
            grouping: {
                type: Array,
                // default: () => {
                //     return {
                //         label: '',
                //         path: '',
                //     };
                // }, @Explanation: You can pass object
            },

            groupingKey: {
                type: String,
                default: 'list',
            },

            // @Explanation: Styling
            surface: {
                type: String,
                default: 'tint-pale',
            },

            // @Explanation: Iteractions
            parentId: [Number, String],

            // @Explanation: Loaders
            hasLoadingList: Boolean,

            // @Explanation: Building dependencies
            isModalMode: Boolean,
            isWithoutHeader: Boolean,
            hasCardAdaptation: Boolean,
        },

        data() {
            return {
                SCROLL_HEIGHT: 12,

                body: null,
                bodyRect: null,
                table: null,
                tableRect: null,
                scroll: null,
                loader: null,

                bodyWidth: null,
                tableWidth: null,

                scrollPosition: null,

                direction: 'desc',

                hasScroll: false,
                isScrolled: false,
            };
        },

        created() {
            window.addEventListener('resize', this.buildScroll);

            window.addEventListener('scroll', this.setLoaderTopPosition);
            window.addEventListener('resize', this.setLoaderTopPosition);
            window.addEventListener('resize', this.setLoaderLeftPosition);
        },

        mounted() {
            this.setScrollData();
            this.setScrollEvents();
            this.setLoaderTopPosition();
            this.setLoaderLeftPosition();

            this.$bus.$on('set-table-scroll', (time) => this.buildScroll(time));
            this.$bus.$on('set-table-loader', (time) => this.updateLoaderPosition(time));
        },

        destroyed() {
            window.removeEventListener('resize', this.buildScroll);

            window.removeEventListener('scroll', this.setLoaderTopPosition);
            window.removeEventListener('resize', this.setLoaderTopPosition);
            window.removeEventListener('resize', this.setLoaderLeftPosition);

            this.$bus.$off('set-table-scroll');
            this.$bus.$off('set-table-loader');
        },

        watch: {
            scrollSetPositionTrigger() {
                this.buildScroll();
                this.updateLoaderPosition();
            },
        },

        computed: {
            ...mapState({
                SHORT_ANIMATION_DELAY: (state) => state.constant.SHORT_ANIMATION_DELAY,
                LONG_ANIMATION_DURATION: (state) => state.constant.LONG_ANIMATION_DURATION,

                DIRECTION_ASC_KEY: (state) => state.constant.DIRECTION_ASC_KEY,
                DIRECTION_DESC_KEY: (state) => state.constant.DIRECTION_DESC_KEY,
            }),

            ...mapGetters({
                hasElusiveThief: 'hasElusiveThief',
            }),

            // @Explanation: Data
            groups() {
                return !this.grouping ? [{ id: 'default', list: this.items }] : this.items;
            },

            activeGrouping() {
                return this.grouping?.find((value) => value.isActive) || null;
            },

            hasDefaultGrouping() {
                return !this.grouping || !this.activeGrouping;
            },

            hasNoItems() {
                return !this.groups.map((group) => group[this.groupingKey]).flat().length;
            },

            // @Explanation: Scroll
            scrollHeight() {
                return this.hasScroll ? this.SCROLL_HEIGHT : 0;
            },

            dopplerHeight() {
                return this.hasElusiveThief ? document.getElementById('doppler')?.clientHeight : 0;
            },

            scrollSetPositionTrigger() {
                return this.groups.map((group) => group[this.groupingKey]).flat().length && this.hasLoadingList;
            },

            hasPagination() {
                return !!this.pagination && !!Object.keys(this.pagination).length;
            },
        },

        methods: {
            async buildScroll(time) {
                await this.initScroll(time);
                this.setScrollPosition();
            },

            updateLoaderPosition(time) {
                this.setLoaderTopPosition(time);
                this.setLoaderLeftPosition(time);
            },

            setScrollData() {
                this.body = this.$refs.body;
                this.table = this.$refs.table;
                this.scroll = this.$refs.scroll;
                this.loader = this.$refs.loader;
            },

            setScrollEvents() {
                document.addEventListener('scroll', this.setScrollPosition);

                this.body?.addEventListener('scroll', this.checkTableScrolling);
                this.body?.addEventListener('scroll', this.setLoaderLeftPosition);

                this.scroll?.addEventListener('scroll', this.scrollUpdate);
            },

            setScrollPosition() {
                this.getTableRect();

                this.hasScroll = this.tableRect.top < window.innerHeight - this.SCROLL_HEIGHT;
                this.scrollPosition =
                    this.tableRect.bottom < window.innerHeight ? window.innerHeight - this.tableRect.bottom - this.SCROLL_HEIGHT : 0;

                this.$bus.$emit('init-bar-position');
            },

            getTableRect() {
                this.tableRect = this.table?.getBoundingClientRect();
            },

            checkTableScrolling() {
                this.isScrolled = !!this.body?.scrollLeft;
            },

            setLoaderLeftPosition() {
                this.body.style.setProperty(`--scroll-left`, `${this.body?.scrollLeft}px`);
                this.loader.style.setProperty(`left`, `calc(50% + ${this.body?.scrollLeft}px)`);
            },

            scrollUpdate() {
                this.body.scrollLeft = this.scroll.scrollLeft;

                this.$bus.$emit('set-fixed-drop-position', 0);
                this.$bus.$emit('make-forced-dd-close');

                this.setStickyColumns();
            },

            onScroll(e) {
                this.scroll.scrollLeft = e.target.scrollLeft;
                this.$bus.$emit('set-fixed-drop-position', 0);

                this.setStickyColumns();
            },

            initScroll(time) {
                this.bodyWidth = null;

                return new Promise((resolve) => {
                    let timer = setTimeout(() => {
                        clearTimeout(timer);
                        this.getTableRect();

                        this.bodyWidth = this.body?.offsetWidth;
                        this.tableWidth = this.table?.offsetWidth;

                        this.hasScroll = !!(
                            this.tableWidth > this.bodyWidth && this.tableRect.top < window.innerHeight - this.SCROLL_HEIGHT
                        );

                        this.setStickyColumns();

                        resolve();
                    }, time || 0); // @Explanation: Waiting for finish of the DOM loading
                });
            },

            setStickyColumns() {
                [...this.table.querySelectorAll('tr')].forEach((row) =>
                    row.querySelectorAll('#sticky').forEach((cell, index, nodeList) => {
                        if (!this.hasScroll) {
                            cell.classList.remove('druk-is-sticky');
                            cell.style.left = null;
                            return;
                        }

                        cell.classList.add('druk-is-sticky');
                        cell.style.left = `0px`;
                    }),
                );
            },

            setLoaderTopPosition(time) {
                let timer = setTimeout(() => {
                    clearTimeout(timer);

                    let bodyRect = this.body?.getBoundingClientRect();

                    let hasVisibleTopGap = bodyRect.top - this.dopplerHeight > 0,
                        hasVisibleBottomGap = bodyRect.bottom < window.innerHeight;

                    let topScreenGap = hasVisibleTopGap ? bodyRect.top - this.dopplerHeight : 0,
                        bottomScreenGap = hasVisibleBottomGap ? window.innerHeight - bodyRect.bottom : 0;

                    let visibleHeight = window.innerHeight - topScreenGap - bottomScreenGap;

                    let topPosition =
                        Math.max((visibleHeight + this.dopplerHeight - (hasVisibleBottomGap ? bottomScreenGap / 2 : 0)) / 2, 0) -
                        bodyRect.top +
                        topScreenGap;

                    this.loader.style.setProperty(`top`, topPosition > 0 ? `${topPosition}px` : '50%');
                }, time || 0);
            },

            updateDirection(key) {
                this.direction =
                    this.sortKey === key
                        ? this.direction === this.DIRECTION_ASC_KEY
                            ? this.DIRECTION_DESC_KEY
                            : null
                        : this.DIRECTION_ASC_KEY;

                this.$emit('onSort', {
                    sort: this.direction ? key : null,
                    direction: this.direction,
                });
            },

            getGroupTitle(group) {
                const base = [...group[this.groupingKey]].shift();

                if (!Array.isArray(this.activeGrouping.key)) return $fn.getDeepValue(this.activeGrouping.key, base);
                return this.activeGrouping.key.map((keyPart) => $fn.getDeepValue(keyPart, base)).join(' – ');
            },

            getGroupPath(group) {
                const base = [...group[this.groupingKey]].shift();

                return {
                    name: this.activeGrouping.link.name,
                    params: Object.fromEntries(
                        this.activeGrouping.link.params.map((item) => {
                            const data = [...item];
                            return [data.shift(), $fn.getDeepValue(data.pop(), base)];
                        }),
                    ),
                };
            },

            getGroupPayloadValue(group, key) {
                const base = [...group[this.groupingKey]].shift();
                return $fn.getDeepValue(this.activeGrouping.payload[key || 'key'], base);
            },
        },
    };
</script>

<style lang="scss" scoped>
    table {
        width: 100%;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        font-weight: 400;
        border: none;
        border-collapse: collapse;
        color: var(--druk-on-surface);
        &::-webkit-scrollbar {
            cursor: initial;
            height: 0;
            width: 0;
            background-color: transparent;
        }
        &::v-deep {
            thead,
            tbody {
                position: relative;
                padding: 0 var(--druk-gap-xl);
                &:last-child {
                    th,
                    td {
                        &::after {
                            content: initial;
                        }
                    }
                }
            }
            thead {
                border-bottom: 1px solid var(--druk-outline-variant);
                th {
                    border-top-left-radius: 12px;
                }
                th.druk-is-sticky {
                    border-top-left-radius: 12px;
                    border-top-right-radius: 12px;
                }
            }
            tbody {
                cursor: pointer;
                border-bottom: 1px solid var(--druk-outline-variant);
                &:hover {
                    &::before {
                        background-color: var(--druk-state-layers-on-surface-0-08);
                    }
                }
                &:active,
                &:focus {
                    &::before {
                        background-color: var(--druk-state-layers-on-surface-0-12);
                    }
                }
                &::before {
                    content: '';
                    pointer-events: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    transition: all 0.2s ease-in-out;
                    z-index: 3;
                }
                &:last-child {
                    border-bottom: 1px solid transparent;
                }
                &:last-child td.druk-is-sticky {
                    border-bottom-right-radius: 12px;
                }
                &.druk-has-active {
                    &::before {
                        background-color: var(--druk-state-layers-secondary-0-08);
                    }
                    &:hover {
                        &::before {
                            background-color: var(--druk-state-layers-on-surface-0-08);
                        }
                    }
                }
            }
            th {
                text-align: left;
                font-weight: 400;
                padding: 0 var(--druk-gap-s);
                height: 60px;
                color: var(--druk-on-surface-variant);
                &:first-child {
                    padding-left: var(--druk-gap-xl);
                }
                &:last-child {
                    padding-right: var(--druk-gap-xl);
                }
            }
            td {
                vertical-align: top;
                padding: var(--druk-gap-m) var(--druk-gap-s);
                &:first-child {
                    padding-left: var(--druk-gap-xl);
                }
                &:last-child {
                    padding-right: var(--druk-gap-xl);
                }
            }
            .druk-is-sticky {
                position: sticky;
                z-index: 2;
                top: 0;
                left: 0;
                background-color: var(--druk-tint-surface-container-pale);
                transition: background-color 0.2s ease-in-out;
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    @media (max-width: $druk-breakpoints-md) {
                        content: initial;
                    }
                }
                @media (max-width: $druk-breakpoints-md) {
                    position: relative;
                    background: transparent;
                    z-index: 0;
                }
            }
            #sticky {
                min-width: 240px;
            }
        }
    }

    table.druk-has-card-adaptation {
        &::v-deep {
            thead,
            tbody {
                @media (max-width: $druk-breakpoints-lg) {
                    padding: 0;
                }
            }
        }
        @media (max-width: $druk-breakpoints-md) {
            display: flex;
            flex-wrap: wrap;
            overflow-x: initial;
            overflow-y: initial;
            min-width: initial;
        }
    }

    .druk-c-table {
        &.druk-is-scrolled {
            &::v-deep .druk-is-sticky {
                background-color: var(--druk-tint-surface-container-brighter);
                @media (max-width: $druk-breakpoints-md) {
                    background-color: initial;
                }
            }
        }

        &.druk-is-scrolled.druk-has-modal-styles {
            &::v-deep .druk-is-sticky {
                background-color: var(--druk-tint-surface-container-mild);
            }
        }
        &.druk-has-card-adaptation &__wrapper {
            @media (max-width: $druk-breakpoints-md) {
                background-color: initial !important;
            }
        }
        &.druk-has-card-adaptation &__body {
            @media (max-width: $druk-breakpoints-md) {
                overflow-x: initial;
                overflow-y: initial;
            }
        }
        &.druk-has-card-adaptation &__header {
            @media (max-width: $druk-breakpoints-md) {
                display: none;
            }
        }
        &.druk-has-card-adaptation &__group-title {
            tr th {
                @media (max-width: $druk-breakpoints-md) {
                    padding: 0;
                    height: initial;
                }
            }
            @media (max-width: $druk-breakpoints-md) {
                display: inline-block;
                flex-grow: 1;
                flex-shrink: 1;
                padding-bottom: var(--druk-gap-l);
                border-color: transparent;
            }
        }
        &.druk-has-card-adaptation &__scroll-layout {
            @media (max-width: $druk-breakpoints-md) {
                opacity: 0;
                visibility: hidden;
            }
        }
        &.druk-has-card-adaptation &__pagination {
            @media (max-width: $druk-breakpoints-md) {
                margin-top: var(--druk-gap-l);
                .druk-c-pgn {
                    border-radius: 16px;
                    border-top: initial;
                }
            }
        }
        &__wrapper {
            display: flex;
            align-items: flex-start;
            border-radius: 12px 12px 0 0;
            &.druk-has-no-pagination {
                border-radius: 12px;
            }
            &.druk-has-no-pagination tbody:last-child::before {
                border-radius: 0 0 12px 12px;
            }
        }
        &__body {
            position: relative;
            width: 100%;
            overflow-x: auto;
            overflow-y: hidden;
            padding-bottom: 0;
            &::before {
                pointer-events: none;
                position: absolute;
                top: 0;
                left: var(--scroll-left);
                width: 100%;
                height: 100%;
                background-color: var(--druk-state-layers-scrim-0-32);
                border-radius: 12px;
                z-index: 4;
            }
            &.druk-has-loader {
                pointer-events: none;
            }
            &.druk-has-loader::before {
                content: '';
            }
            &.druk-has-loader .druk-c-table__loader {
                opacity: 1;
            }
            &.druk-has-loader .druk-c-table__prompt {
                display: none;
            }
        }
        &__header {
            &-item {
                cursor: pointer;
            }
            &-item.druk-has-actions {
                display: flex;
                justify-content: flex-end;
            }
            &-data {
                display: inline-flex;
                align-items: center;
            }
            &-text {
                display: inline;
                line-height: 20px;
            }
            &-sort {
                cursor: pointer;
                position: relative;
                padding: 0 4px;
                opacity: 0.38;
                transition: opacity 0.2s ease-in-out;
            }
            &-sort:hover {
                opacity: 1;
            }
            &-sort.druk-is-picked {
                opacity: 1;
            }
            &-sort &-sort-icon.druk-is-desc {
                opacity: 1;
            }
            &-sort &-sort-icon.druk-is-asc {
                opacity: 1;
            }
            &-sort &-sort-icon.druk-is-default {
                opacity: 0.38;
            }
            &-sort-icon.druk-is-desc,
            &-sort-icon.druk-is-asc {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, 0%);
                opacity: 0;
            }
        }
        &__group-title {
            &.druk-has-payload {
                white-space: nowrap;
            }
            &.druk-has-no-header:first-child tr th {
                border-top-left-radius: 12px;
            }
            tr th {
                padding: var(--druk-gap-m) var(--druk-gap-xl);
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.1px;
                font-weight: 600;
            }
            tr th.druk-is-sticky {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }
        &__group-payload {
            margin-left: var(--druk-gap-s);
        }
        &__scroll-layout {
            cursor: pointer;
            position: fixed;
            flex-shrink: 0;
            z-index: 2;
            &.has-loader {
                pointer-events: none;
            }
        }
        &__scroll {
            overflow: auto;
            span {
                pointer-events: none;
                display: block;
                height: 10px;
            }
        }
        &__prompt {
            padding: var(--druk-gap-m) var(--druk-gap-xl);
            width: 100%;
        }
        &__loader {
            pointer-events: none;
            position: absolute;
            display: flex;
            align-items: center;
            padding: var(--druk-gap-m) var(--druk-gap-l);
            color: var(--druk-on-surface);
            background-color: var(--druk-surface-container);
            transform: translate(-50%, -50%);
            opacity: 0;
            z-index: 5;
            transition: opacity 0.2s ease-in-out;
            .druk-c-loader {
                margin-right: var(--druk-gap-s);
            }
        }
    }

    .druk-l-subtable {
        display: none;
        &.druk-is-active {
            display: table-row-group;
        }
        &.druk-is-last {
            overflow: hidden;
            border-radius: 0 0 12px 12px;
            &::v-deep tr td {
                &:first-child {
                    border-bottom-left-radius: 12px;
                }
                &:last-child {
                    border-bottom-right-radius: 12px;
                }
            }
            &::before {
                border-radius: 0 0 12px 12px;
            }
        }
        &::v-deep {
            tr td {
                color: var(--druk-on-surface-variant);
            }
        }
        &:nth-child(odd)::v-deep {
            tr td {
                background-color: var(--druk-primary-tint-0-11);
            }
        }
        &:nth-child(even)::v-deep {
            tr td {
                background-color: var(--druk-secondary-tint-0-08);
            }
        }
    }

    .druk-is-last:not(.druk-is-active) {
        border-color: transparent;
    }
</style>
