import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        config: {
            value: null,
            data: null,
        },

        SAVE_STAGES_LIST: [
            {
                value: 1,
                key: 'kinds.save_wizard_stages.main',
                title: null,
                route: 'kinds_item_save_main',
                is_done: 0,
                is_dirty: 0,
                is_missing: 0,
            },
            {
                value: 2,
                key: 'products.save_wizard_stages.tech',
                title: null,
                route: 'kinds_item_save_tech',
                is_done: 0,
                is_missing: 0,
            },
            {
                value: 3,
                key: 'products.save_wizard_stages.preview',
                title: null,
                route: 'kinds_item_save_preview',
                is_done: 0,
                is_missing: 0,
            },
        ],

        SAVE_STAGE_MAIN: 1,
        SAVE_STAGE_TECH: 2,
        SAVE_STAGE_PREVIEW: 3,

        CALCULATION_VARIETIES: [
            {
                value: 'fixed',
                key: 'products.calculation_varities.fixed',
                title: null,
            },
            {
                value: 'range',
                key: 'products.calculation_varities.range',
                title: null,
            },
            {
                value: 'curve',
                key: 'products.calculation_varities.curve',
                title: null,
            },
        ],

        CALCULATION_VARIETY_FIXED: 'fixed',
        CALCULATION_VARIETY_RANGE: 'range',
        CALCULATION_VARIETY_CURVE: 'curve',

        WIZARD_CONFIG_SETTINGS: {
            product: null,
            kind: null,
            details: [],
            kindDetails: [],
            algorithms: [],
            kindAlgorithms: {},
            works: [],
            equipments: [],
            equipmentModes: {},
            materials: [],
            materialKinds: {},

            wizardModes: {},
            unsavedData: {},
        },

        WIZARD_SETTINGS_EXTRA_DATA_KEYS: [
            'details',
            'kindDetails',
            'algorithms',
            'kindAlgorithms',
            'works',
            'equipments',
            'materials',
            'equipmentModes',
            'materialKinds',
        ],
        WIZARD_UNSAVED_DATA_DETAIL_KEYS: ['detailData', 'kindDetailData'],
        WIZARD_UNSAVED_DATA_ALGORITHM_KEYS: ['algorithmData', 'kindAlgorigthmData'],
        WIZARD_UNSAVED_DATA_WORK_KEYS: ['workData', 'workEquipmentData', 'workMaterialData'],
        WIZARD_UNSAVED_DATA_EQUIPMENT_KEYS: [
            'equipmentData',
            'equipmentTableData',
            'equipmentOptionData',
            'equipmentOptionValueData',
            'equipmentVariableData',
            'equipmentRapportsData',
        ],
        WIZARD_UNSAVED_DATA_MATERIAL_KEYS: [
            'materialData',
            'materialTableData',
            'materialOptionData',
            'materialOptionValueData',
            'materialVariableData',
        ],

        WIZARD_MODES_COMMON: {
            // details
            hasDetailAddMode: false,
            hasDetailCreatingMode: false,

            // algorithms
            hasAlgorithmAddMode: false,
            hasAlgorithmCreatingMode: false,

            // works
            hasWorkAddMode: false,
            hasWorkCreatingMode: false,

            // equipments
            hasEquipmentAddMode: false,
            hasEquipmentCreatingMode: false,
            hasEquipmentTableCreatingMode: false,
            hasEquipmentOptionAddMode: false,
            hasEquipmentOptionCreationMode: false,
            hasEquipmentOptionValueCreationMode: false,
            hasEquipmentVariableAddMode: false,
            hasEquipmentVariableCreationMode: false,

            // materials
            hasMaterialAddMode: false,
            hasMaterialCreatingMode: false,
            hasMaterialTableCreatingMode: false,
            hasMaterialOptionAddMode: false,
            hasMaterialOptionCreationMode: false,
            hasMaterialOptionValueCreationMode: false,
            hasMaterialVariableAddMode: false,
            hasMaterialVariableCreationMode: false,
        },

        stage: {},
        wizardPayload: {},
        wizardModes: {},

        hasEmptyWizard: true,
    },

    getters: {
        hasDetailsWizardMode(state) {
            return !!(state.wizardModes.hasDetailAddMode || state.wizardModes.hasDetailCreatingMode);
        },

        hasAlgorithmWizardMode(state, getters) {
            return !!(state.wizardModes.hasAlgorithmAddMode || getters.hasOperationsWizardMode);
        },

        hasOperationsWizardMode(state, getters) {
            return !!(state.wizardModes.hasWorkAddMode || getters.hasWorkWizardMode);
        },

        hasWorkWizardMode(state, getters) {
            return !!(
                state.wizardModes.hasWorkCreatingMode ||
                state.wizardModes.hasEquipmentAddMode ||
                state.wizardModes.hasMaterialAddMode ||
                getters.hasEquipmentWizardMode ||
                getters.hasMaterialWizardMode
            );
        },

        hasEquipmentWizardMode(state, getters) {
            return !!(state.wizardModes.hasEquipmentCreatingMode || getters.hasEquipmentTableWizardMode);
        },

        hasEquipmentTableWizardMode(state) {
            return !!(
                state.wizardModes.hasEquipmentTableCreatingMode ||
                state.wizardModes.hasEquipmentOptionAddMode ||
                state.wizardModes.hasEquipmentOptionCreationMode ||
                state.wizardModes.hasEquipmentOptionValueCreationMode ||
                state.wizardModes.hasEquipmentVariableAddMode ||
                state.wizardModes.hasEquipmentVariableCreationMode
            );
        },

        hasMaterialWizardMode(state, getters) {
            return !!(state.wizardModes.hasMaterialCreatingMode || getters.hasMaterialTableCreatingMode);
        },

        hasMaterialTableWizardMode(state) {
            return !!(
                state.wizardModes.hasMaterialTableCreatingMode ||
                state.wizardModes.hasMaterialOptionAddMode ||
                state.wizardModes.hasMaterialOptionCreationMode ||
                state.wizardModes.hasMaterialOptionValueCreationMode ||
                state.wizardModes.hasMaterialVariableAddMode ||
                state.wizardModes.hasMaterialVariableCreationMode
            );
        },
    },

    actions: {},

    mutations: {
        SET_VALUE(state, value) {
            state.config.value = value;
        },

        SET_DATA(state, value) {
            state.config.data = value;
        },

        SET_STAGE(state, value) {
            Vue.set(state, 'stage', value);
        },

        SET_STAGE_DATA(state, { stage, key, value }) {
            let index = state.SAVE_STAGES_LIST.findIndex((item) => item.value === stage);
            Vue.set(state.SAVE_STAGES_LIST[index], key, value);
        },

        SET_WIZARD_PAYLOAD(state, { key, value }) {
            Vue.set(state.wizardPayload, key, value);
        },

        SET_WIZARD_MODES(state, value) {
            state.wizardModes = value;
        },

        SET_WIZARD_MODE(state, { key, value }) {
            Vue.set(state.wizardModes, key, value);
        },

        SET_WIZARD_SETTINGS_PROP(state, { key, value }) {
            Vue.set(state.wizardPayload.config.wizard_settings, key, value);
        },

        PUSH_TO_WIZARD_SETTINGS(state, { key, value }) {
            let map = state.wizardPayload.config.wizard_settings[key].concat(value);
            Vue.set(state.wizardPayload.config.wizard_settings, key, map);
        },

        SLICE_FROM_WIZARD_SETTINGS(state, { key, value }) {
            let index = state.wizardPayload.config.wizard_settings[key].indexOf(value),
                map = state.wizardPayload.config.wizard_settings[key]
                    .slice(0, index)
                    .concat(state.wizardPayload.config.wizard_settings[key].slice(index + 1));

            Vue.set(state.wizardPayload.config.wizard_settings, key, map);
        },

        DELETE_WIZARD_SETTINGS_PROP_VALUES(state, { key, value }) {
            let obj = Object.create(null);
            Object.keys(state.wizardPayload.config.wizard_settings[key]).forEach((propKey) => {
                let propValue = state.wizardPayload.config.wizard_settings[key][propKey];
                if (propValue !== value) obj[propKey] = propValue;
            });

            Vue.set(state.wizardPayload.config.wizard_settings, key, obj);
        },

        SET_WIZARD_STATUS(state, value) {
            state.hasEmptyWizard = value;
        },

        FILL_WIZARD(state, list) {
            state.hasEmptyWizard = false;
            state.SAVE_STAGES_LIST = list;
        },

        FINISH_WIZARD(state) {
            state.SAVE_STAGES_LIST = state.SAVE_STAGES_LIST.map((item) =>
                Object.assign(item, {
                    is_done: 0,
                    is_dirty: 0,
                    is_missing: 0,
                    is_broken: 0,
                }),
            );

            state.hasEmptyWizard = true;
        },

        RESET_WIZARD_PAYLOAD(state) {
            state.wizardPayload = {};
        },

        RESET_WIZARD_MODES(state) {
            state.wizardModes = {};
        },

        RESET_WIZARD_UNSAVED_DATA_PROPS(state, keys) {
            keys.forEach((key) => (state.wizardPayload.config.wizard_settings.unsavedData[key] = {}));
        },
    },
};
