var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"dd",staticClass:"druk-c-dd",class:{ 'druk-has-static': _vm.hasStaticContent, 'druk-is-block': _vm.isBlock },attrs:{"id":`dd-${_vm.hash}`}},[_c('div',{staticClass:"druk-c-dd__wrapper"},[_c('div',{staticClass:"druk-c-dd__base"},[_vm._t("druk-c-dd-base",null,{"isShown":_vm.isShown})],2),_c('div',{ref:"dd-content",staticClass:"druk-c-dd__content",class:{
                [`druk-l-surface-${_vm.surface}`]: !!_vm.surface,
                'druk-is-shown': _vm.isShown,
                'druk-is-temporary-hidden': _vm.isTemporaryHidden && !_vm.hasStaticContent,
                [`druk-is-from-${_vm.position.horizontal}`]: !!_vm.position,
                [`druk-is-from-${_vm.position.vertical}`]: !!_vm.position,
                'druk-is-static': _vm.hasStaticContent,
                'druk-is-reduced': _vm.isReduced,
                'druk-is-small': _vm.isSmall,
            },style:(_vm.variables)},[_vm._t("druk-c-dd-list")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }