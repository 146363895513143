import Vue from 'vue';

import axios from 'axios';

import modify from './modify';

const DEFAULT_FORM_DATA = {
    details: [],
    principle: null,
    use_custom_roll_diameter: false,
};

const DEFAULT_PARAMETERS = {
    count: {
        count: 1,
    },

    format: {
        width: null,
        height: null,
        core_diameter: null,
        roll_diameter: null,
    },

    label_format: {
        label_width: null,
        label_height: null,
        padding: null,
        indent_left: null,
        indent_right: null,
        angle_corner_radius: null,
        winding_direction: null,
        rotation: null,
        label_type: null,
    },
};

const CancelToken = axios.CancelToken;

let cancel;

const getParamsData = (detail, index, paramKey) => {
    if (!detail.params?.[paramKey]) return {};

    return Object.fromEntries(
        Object.entries(detail.params[paramKey]).map(([key, value]) => [`details[${index}][params][${paramKey}][${key}]`, value]),
    );
};

const getRapportsData = (detail, index) => {
    if (!detail.rapports) return {};

    let rapportsData = Object.create(null);

    detail.rapports.forEach((rapport, rapportIndex) => {
        let data = Object.fromEntries(
            Object.entries(rapport).map(([key, value]) => [`details[${index}][rapports][${rapportIndex}][${key}]`, value]),
        );

        Object.assign(rapportsData, data);
    });

    return rapportsData;
};

export default {
    namespaced: true,

    modules: {
        modify,
    },

    state: {
        formData: {
            ...DEFAULT_FORM_DATA,

            parameters: {
                ...DEFAULT_PARAMETERS,
            },
        },

        modifyData: {},

        calculation: {},
        recs: {},
        logs: {},
        message: null,
        winners: [],

        loadingGetCalculation: false,
    },

    getters: {},

    actions: {
        GET_CALCULATION: async ({ rootGetters, commit }, { productId, id, formData }) => {
            try {
                if (cancel !== undefined) cancel();

                commit('SET_LOADING_STATUS', { value_key: 'loadingGetCalculation', status: true });

                const path = `/api/typographies/${rootGetters[`currentTypography`].id}/products/${productId}/kinds/${id}`;

                let { details, ...data } = formData;

                let params = Object.assign(data, {
                    with_logs: 1,
                    with_calculation: 1,
                    new_format: 1,
                });

                details.forEach((detail, index) => {
                    let materialParams = getParamsData(detail, index, 'material'),
                        equipmentParams = getParamsData(detail, index, 'equipment');

                    let rapports = getRapportsData(detail, index);

                    Object.assign(
                        params,
                        {
                            [`details[${index}][id]`]: detail.id,
                            [`details[${index}][algorithm_id]`]: detail.algorithm_id,
                            [`details[${index}][count]`]: detail.count,
                        },
                        materialParams,
                        equipmentParams,
                        rapports,
                    );
                });

                let resp = await $axios.get(path, {
                    cancelToken: new CancelToken(function executor(c) {
                        cancel = c;
                    }),

                    params,
                });

                commit('SET_CALCULATION', resp.calculations);
                commit('SET_RECS', resp.recommendations);
                commit('SET_LOGS', resp.logs);
                commit('SET_MESSAGE', null);
                commit('SET_WINNERS', resp.winners);

                return resp;
            } catch (e) {
                if (!e.response) return;

                commit('SET_LOGS', e.response.data.logs);
                commit('SET_MESSAGE', e.response.data.message);
                commit('RESET_CALCULATION');
                commit('RESET_RECS');
                commit('RESET_WINNERS');

                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetCalculation', status: false });
            }
        },

        INTERRUPT_CALCULATION: async () => {
            if (cancel !== undefined) cancel();
        },
    },

    mutations: {
        SET_CALCULATION(state, calculation) {
            state.calculation = calculation;
        },

        SET_RECS(state, recs) {
            state.recs = recs;
        },

        SET_LOGS(state, logs) {
            state.logs = logs;
        },

        SET_MESSAGE(state, message) {
            state.message = message;
        },

        SET_WINNERS(state, winners) {
            state.winners = winners;
        },

        SET_FORM_DATA(state, formData) {
            Vue.set(state, 'formData', { ...DEFAULT_FORM_DATA, ...formData });
        },

        SET_FORM_DATA_PROP(state, { key, value }) {
            Vue.set(state.formData, key, value);
        },

        SET_FORM_DATA_PARAMETERS(state, parameters) {
            Vue.set(state.formData, 'parameters', { ...DEFAULT_PARAMETERS, ...parameters });
        },

        SET_FORM_DATA_COUNT(state, data) {
            Vue.set(state.formData.parameters, 'count', { ...DEFAULT_PARAMETERS.count, ...data });
        },

        SET_FORM_DATA_COUNT_PROP(state, { key, value }) {
            Vue.set(state.formData.parameters.count, key, value);
        },

        SET_FORM_DATA_FORMAT(state, data) {
            Vue.set(state.formData.parameters, 'format', { ...DEFAULT_PARAMETERS.format, ...data });
        },

        SET_FORM_DATA_FORMAT_PROP(state, { key, value }) {
            Vue.set(state.formData.parameters.format, key, value);
        },

        SET_FORM_DATA_LABEL_FORMAT(state, data) {
            Vue.set(state.formData.parameters, 'label_format', { ...DEFAULT_PARAMETERS.label_format, ...data });
        },

        SET_FORM_DATA_LABEL_FORMAT_PROP(state, { key, value }) {
            Vue.set(state.formData.parameters.label_format, key, value);
        },

        SET_MODIFY_DATA(state, { key, value }) {
            Vue.set(state.modifyData, key, value);
        },

        UPDATE_FORM_DATA_COUNT(state, data) {
            Vue.set(state.formData.parameters, 'count', { ...state.formData.parameters.count, ...data });
        },

        UPDATE_FORM_DATA_FORMAT(state, data) {
            Vue.set(state.formData.parameters, 'format', { ...state.formData.parameters.format, ...data });
        },

        UPDATE_FORM_DATA_LABEL_FORMAT(state, data) {
            Vue.set(state.formData.parameters, 'label_format', { ...state.formData.parameters.label_format, ...data });
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            Vue.set(state, value_key, status);
        },

        RESET_FORM_DATA(state) {
            Vue.set(state, 'formData', {
                ...DEFAULT_FORM_DATA,

                parameters: {
                    ...DEFAULT_PARAMETERS,
                },
            });
        },

        RESET_FORM_DATA_COUNT(state) {
            Vue.set(state.formData.parameters, 'count', { ...DEFAULT_PARAMETERS.count });
        },

        RESET_FORM_DATA_FORMAT(state) {
            Vue.set(state.formData.parameters, 'format', { ...DEFAULT_PARAMETERS.format });
        },

        RESET_FORM_DATA_LABEL_FORMAT(state) {
            Vue.set(state.formData.parameters, 'label_format', { ...DEFAULT_PARAMETERS.label_format });
        },

        RESET_MODIFY_DATA(state) {
            state.modifyData = {};
        },

        RESET_CALCULATION(state) {
            state.calculation = {};
        },

        RESET_RECS(state) {
            state.recs = {};
        },

        RESET_LOGS(state) {
            state.logs = {};
        },

        RESET_MESSAGE(state) {
            state.message = null;
        },

        RESET_WINNERS(state) {
            state.winners = [];
        },
    },
};
