<template>
    <div class="druk-c-form-txt-fld">
        <div
            class="selector selector--select selector--select-of-add"
            :class="{
                'has-focus': isActive,
                'has-full': isFull,
                'has-error': errors.has(name),
                'has-loading': isLoading || hasListLoading,
                'druk-is-disabled': (options.length <= 1 && isFull) || isDisabled,
            }">
            <div v-if="label" class="selector__label" :class="{ [`druk-l-surface-${surface}`]: surface }" @click="onToggle">
                <span>
                    <label>{{ label }}</label>
                    <template v-if="isRequired">*</template>
                </span>

                <druk-hint
                    v-if="hint"
                    :tooltip="{ text: hint.text || hint, from: 'top', maxWidth: hint.maxWidth, isNotCollapse: hint.isNotCollapse }"
                    :icon="{ name: 'circle-info', size: 'xs', color: isActive ? 'primary' : 'outline' }" />
            </div>

            <div class="selector__main" :style="{ 'background-color': surface }">
                <multiselect
                    v-model="valueModel"
                    v-validate="rules"
                    ref="multiselect"
                    :style="{ [`--druk-content-background`]: contentBackground }"
                    class="selector__area"
                    :data-vv-name="name"
                    :data-vv-as="label"
                    :options="formattedOptions"
                    :track-by="optionId"
                    :label="optionLabel"
                    :placeholder="placeholder"
                    :groupLabel="hasGroupSelection ? 'title' : groupLabel || null"
                    :groupValues="hasGroupSelection ? 'options' : groupValues || null"
                    :groupSelect="hasGroupSelection"
                    :tagPlaceholder="$t('select.paceholder.create')"
                    :selectLabel="''"
                    :selectedLabel="''"
                    :selectGroupLabel="''"
                    :deselectGroupLabel="''"
                    :deselectLabel="''"
                    :multiple="multiple"
                    :taggable="taggable"
                    :close-on-select="!multiple"
                    @search-change="onSearch"
                    @tag="onTag($event)"
                    @open="isActive = true"
                    @close="onClose">
                    <template slot="noResult">
                        <span>{{ $t('common.multiple_async.no_result') }}</span>
                    </template>

                    <template slot="option" slot-scope="props">
                        <div
                            class="multiselect__option-body"
                            :class="{
                                'druk-is-select-all': props.option.$isLabel,
                                'druk-is-multiple': multiple && !props.option.$isLabel,
                            }">
                            <template v-if="multiple">
                                <div
                                    v-if="isSomeOptionsSelected && props.option.$isLabel"
                                    class="multiselect__option-icon multiselect__option-icon--empty">
                                    <druk-icon :name="'square-minus'" :variant="'solid'" :isInline="true" />
                                </div>

                                <div
                                    v-else
                                    class="multiselect__option-icon multiselect__option-icon--empty"
                                    :class="{ 'druk-is-hidden': props.option.$isDisabledSelected }">
                                    <druk-icon :name="'square'" :color="'on-surface-variant'" :isInline="true" />
                                </div>

                                <div
                                    class="multiselect__option-icon multiselect__option-icon--selected"
                                    :class="{ 'druk-is-visible': props.option.$isDisabledSelected }">
                                    <druk-icon
                                        :name="'square-check'"
                                        :variant="'solid'"
                                        :color="props.option.$isDisabledSelected ? 'on-surface' : 'primary'"
                                        :isInline="true" />
                                </div>
                            </template>

                            <span v-if="props.option.$isLabel">{{ props.option.$groupLabel }}</span>

                            <span v-else>{{ props.option[optionLabel] || props.option.label }}</span>
                        </div>
                    </template>

                    <template slot="selection"> {{ isActive ? '' : multipleLabel }} </template>
                </multiselect>

                <div class="selector__select" :class="{ 'has-active': isFull || isActive }" @click="onToggle">
                    <font-awesome-icon icon="fa-regular fa-angle-down" />
                </div>
            </div>
        </div>

        <div class="druk-c-form-txt-fld__hint">
            <druk-hint :tooltip="searchHint" :icon="searchHint.icon" />
        </div>
    </div>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

    import axios from 'axios';

    import Multiselect from 'vue-multiselect';

    const CancelToken = axios.CancelToken;

    let cancel;

    export default {
        name: 'druk-form-async-select',

        inject: ['$validator'],

        components: {
            Multiselect,
        },

        props: {
            // @Explanation: Model
            value: [Object, Array],

            // @Explanation: Options
            options: {
                type: Array,
                default: () => {
                    return [];
                },
            },
            optionId: {
                type: String,
                default: 'id',
            },
            optionLabel: {
                type: String,
                default: 'title',
            },
            disabledOptions: {
                type: Array,
                default: () => {
                    return [];
                },
            },

            groupLabel: String,
            groupValues: String,

            isAllSelectionUnavail: Boolean,
            hasAlphaSort: Boolean,

            // @Explanation: Async props
            route: String,

            params: {
                type: Object,
                default: () => ({}),
            },
            filterKey: {
                type: String,
                default: 'title',
            },
            limit: {
                type: [String, Number],
                default: 15,
            },

            // @Explanation: Component building blocks
            label: String,
            placeholder: {
                type: String,
                default: '',
            },
            hint: [String, Object],

            // @Explanation: Multiselect API
            multiple: Boolean,
            taggable: Boolean,

            // @Explanation: Validation
            rules: {
                type: [String, Object, Array],
                default: '',
            },
            name: String,

            // @Explanation: Styling
            surface: {
                type: String,
                default: 'tint-pale',
            },
            surfaceVariant: {
                type: String,
                default: 'tint-bright',
            },

            // @Explanation: States
            isLoading: Boolean,
            isDisabled: Boolean,
        },

        data() {
            return {
                list: this.options,

                typingTimer: null,

                isActive: false,
                hasListLoading: false,
            };
        },

        watch: {
            options: function (newValue, oldValue) {
                if (JSON.stringify(newValue) === JSON.stringify(oldValue)) return;
                this.onSetList(newValue);
            },
        },

        computed: {
            ...mapGetters({
                currentTypography: 'currentTypography',
            }),

            valueModel: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.onInput(value);
                },
            },

            formattedOptions() {
                const list = this.hasGroupSelection
                    ? [{ title: this.$t('common.select_all'), options: this.formattedList }]
                    : this.formattedList;

                return $fn.fillList(list);
            },

            formattedList() {
                return this.list.map((item) => {
                    let disabled = this.disabledOptions.find((option) => option[this.optionId] === item[this.optionId]);

                    return {
                        ...item,
                        $isDisabled: !!disabled || false,
                        $isDisabledSelected: this.multiple
                            ? !!this.value.find((valueItem) => valueItem[this.optionId] === disabled?.[this.optionId])
                            : this.value?.[this.optionId] === disabled?.[this.optionId],
                    };
                });
            },

            multipleLabel() {
                if (!this.multiple || !this.valueModel) return null;

                return this.valueModel.length > 1
                    ? `${this.$t('filter.values')}: ${this.valueModel.length}`
                    : [...this.valueModel].shift()?.[this.optionLabel] || '';
            },

            searchHint() {
                return {
                    from: 'top-left',
                    icon: 'file-magnifying-glass',
                    text: this.$t('form.async_select.hint'),
                    maxWidth: '320px',
                };
            },

            contentBackground() {
                let base = this.surfaceVariant.split('-');
                return `var(--druk-${[...base].shift()}-surface-container-${[...base].pop()})`;
            },

            isFull() {
                return !!this.value;
            },

            isRequired() {
                return (this.rules && this.rules.require) || this.rules.includes('require');
            },

            hasGroupSelection() {
                return (this.multiple && !this.isAllSelectionUnavail) || (this.groupLabel && this.groupValues);
            },

            isSomeOptionsSelected() {
                return Array.isArray(this.value) && this.value.length;
            },
        },

        methods: {
            onSetList(list) {
                this.list = list.sort((prev, next) => {
                    return this.hasAlphaSort
                        ? prev[this.optionLabel]?.localeCompare(next[this.optionLabel])
                        : next[this.optionId] - prev[this.optionId];
                });
            },

            onToggle() {
                this.isActive ? this.$refs.multiselect.deactivate() : this.$refs.multiselect.activate();
            },

            async onSearch(value) {
                await this.resetTimer();

                const filteredList = this.list.filter((item) => item[this.optionLabel].toLowerCase().includes(value.toLowerCase()));

                this.typingTimer = setTimeout(async () => {
                    if (
                        filteredList.length < this.limit ||
                        !this.list.find((item) => item[this.optionLabel].toLowerCase() === value.toLowerCase())
                    ) {
                        let addlList = await this.getList(value);

                        addlList = addlList.filter(
                            (addlItem) => !this.list.find((item) => item[this.optionId] === addlItem[this.optionId]),
                        );

                        this.onSetList(this.list.concat(addlList));
                    }
                }, 500);
            },

            resetTimer() {
                return new Promise((resolve) => {
                    clearTimeout(this.typingTimer);
                    this.typingTimer = null;

                    resolve();
                });
            },

            async getList(value) {
                let list = [];

                try {
                    this.hasListLoading = true;

                    if (cancel) cancel();

                    let resp = await this.$axios.get(`/api/typographies/${this.currentTypography.id}/${this.route}`, {
                        params: {
                            ...this.params,
                            [`${this.filterKey}`]: value,
                            limit: this.limit,
                            page: 1,
                        },

                        cancelToken: new CancelToken(function executor(c) {
                            cancel = c;
                        }),
                    });

                    return (list = resp.list);
                } catch (e) {
                } finally {
                    this.hasListLoading = false;
                }

                return list;
            },

            onInput(value) {
                this.$emit('input', value);
            },

            onTag(value) {
                if (!this.multiple) this.onToggle();

                this.$emit('tag', {
                    [this.optionId]: null,
                    [this.optionLabel]: value,
                });
            },

            onClose() {
                this.isActive = false;
                this.$emit('close');
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-form-txt-fld {
        display: flex;
        &__hint {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 6px;
        }
    }
</style>
