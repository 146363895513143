import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        // @Explanation: access types
        PREM_TYPE_ACCESS_COMMON: 'common',
        PREM_TYPE_ACCESS_ERP: 'erp',
        PREM_TYPE_ACCESS_TYPOGRAPHY: 'typography',

        PERM_ERP_ACCESS_TYPES: ['erp', 'common'],
        PERM_TYPOGRAPHY_ACCESS_TYPES: ['typography', 'common'],

        // @Explanation: Common modules
        PERM_MODULE_DASHBOARD: 'front_dashboard',

        PERM_MODULE_EMPLOYEES: 'front_employees',
        PERM_MODULE_ROLES: 'front_roles',

        PERM_MODULE_CATALOG: 'front_catalog',
        PERM_MODULE_PRODUCTS: 'front_products',
        PERM_MODULE_TECHNICAL_MAP: 'front_technicalMap',

        PERM_MODULE_DETAILS: 'front_details',
        PERM_MODULE_FORMATS: 'front_formats',
        PERM_MODULE_SETTINGS: 'front_settings',

        PERM_MODULE_TYPOGRAPHIES: 'front_typographies',
        PREM_MODULE_DELIVERY_OPTIONS: 'front_deliveryOptions',

        PERM_MODULE_PAGES: 'front_pages',
        PERM_MODULE_TRANSLATES: 'front_translates',

        // @Explanation: ERP modules
        PERM_MODULE_CLIENT_MODULES: 'front_clientModules',
        PERM_MODULE_ROLE_TEMPLATES: 'front_roleTemplates',
        PERM_MODULE_PARTNERS: 'front_partners',

        PERM_MODULE_PARTNER_PAGES: 'front_partnerPages',
        PERM_MODULE_TRANSLATE_SCOPES: 'front_translateScopes',

        PERM_MODULE_CURRENCY: 'front_currency',

        // @Explanation: Typography modules
        PERM_MODULE_ORDERS: 'front_orders',
        PERM_MODULE_ORDER_COMMENTS: 'front_orderComments',
        PERM_MODULE_ORDER_MOCKUP: 'front_ordermockup',
        PERM_MODULE_CLIENTS: 'front_clients',
        PERM_MODULE_FEEDBACK: 'front_feedback',

        PERM_MODULE_TRANSACTIONS: 'front_transactions',

        PERM_MODULE_TM_OPERATIONS: 'front_tasks',
        PERM_MODULE_TM_OPERATIONS_PRE: 'front_tasksPre',
        PERM_MODULE_TM_OPERATIONS_PRINT: 'front_tasksPrint',
        PERM_MODULE_TM_OPERATIONS_POST: 'front_tasksPost',
        PERM_MODULE_TM_OPERATIONS_LOGISTIC: 'front_tasksLogistic',
        PERM_MODULE_TM_OPERATIONS_COMMENTS: 'front_tasksComments',

        PERM_MODULE_STORE: 'front_store',
        PERM_MODULE_CONTRACTORS: 'front_contractors',

        PERM_MODULE_OPERATIONS: 'front_operations',
        PERM_MODULE_ALGORITHMS: 'front_algorithms',
        PERM_MODULE_MATERIALS: 'front_materials',
        PERM_MODULE_EQUIPMENTS: 'front_equipments',
        PERM_MODULE_LIBRARY_PARAMS: 'front_libraryParams',

        PERM_MODULE_BLOGS: 'front_blogs',
        PERM_MODULE_SEO: 'front_seo',
        PERM_MODULE_MAIL_TEMPLATE: 'front_mailTemplate',

        PERM_MODULE_TYPOGRAPHY_CURRENCY: 'front_typographyCurrency',

        //client module's actions
        PERM_ACTION_GET: 'get',
        PERM_ACTION_VIEW: 'view',
        PERM_ACTION_ADD: 'add',
        PERM_ACTION_EDIT: 'edit',
        PERM_ACTION_DELETE: 'delete',
        PERM_ACTION_ADDITIONAL: 'additional',

        // @Explanation: Module chapters maps
        PERM_MODULE_TM_OPERATIONS_CHAPTERS: ['front_tasksPre', 'front_tasksPrint', 'front_tasksPost', 'front_tasksLogistic'],

        PERM_DEFAULT_PAYLOAD: {
            front_employees: ['get'],
            employees: ['get'],
            front_typographies: ['get'],
            typographies: ['get'],
        },

        list: [],

        formData: {},

        loadingGetList: false,
    },

    getters: {
        ERPList(state) {
            return state.list
                .filter((group) => state.PERM_ERP_ACCESS_TYPES.includes(group.typeAccess) || !group.typeAccess)
                .map((group) => ({
                    ...group,
                    modules: group.modules
                        .filter((module) => state.PERM_ERP_ACCESS_TYPES.includes(module.typeAccess) || !module.typeAccess)
                        .map((module) => ({
                            ...module,
                            actions: module.actions.filter(
                                (action) => state.PERM_ERP_ACCESS_TYPES.includes(action.typeAccess) || !action.typeAccess,
                            ),
                        })),
                }));
        },

        typographyList(state) {
            return state.list
                .filter((group) => state.PERM_TYPOGRAPHY_ACCESS_TYPES.includes(group.typeAccess) || !group.typeAccess)
                .map((group) => ({
                    ...group,
                    modules: group.modules
                        .filter((module) => state.PERM_TYPOGRAPHY_ACCESS_TYPES.includes(module.typeAccess) || !module.typeAccess)
                        .map((module) => ({
                            ...module,
                            actions: module.actions.filter(
                                (action) => state.PERM_TYPOGRAPHY_ACCESS_TYPES.includes(action.typeAccess) || !action.typeAccess,
                            ),
                        })),
                }));
        },

        formattedList(state) {
            let list = Object.create(null);
            state.list
                .map((group) => group.modules)
                .flat()
                .forEach((module) => (list[module.key] = module));

            return list;
        },
    },

    actions: {
        GET_LIST: async ({ state, getters, commit }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let resp = await $axios.get('/api/permissions');

                commit('SET_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_BUILDED_LIST(state, buildedList) {
            state.buildedList = buildedList;
        },

        SET_FORM_DATA(state, formData) {
            Vue.set(state, 'formData', formData);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        RESET_FORM_DATA(state) {
            state.formData = {};
        },
    },
};
