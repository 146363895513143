<template>
    <div
        class="selector selector--input selector--input-num"
        :class="{
            'has-focus': hasActive,
            'has-full': hasFull,
            'has-error': errors.has(name) || hasError,
            'has-loading': loader,
            'druk-has-hint': hint && !hint.isSide,
            'druk-has-side-hint': hint && hint.isSide,
        }">
        <div v-if="label" class="selector__label" :class="{ [`druk-l-surface-${surface}`]: surface }" @click="onToggle">
            <span>
                <label>{{ label }}</label>
                <template v-if="isRequired">*</template>
            </span>

            <druk-hint
                v-if="hint && !hint.isSide"
                :tooltip="tooltip"
                :icon="{ name: hint.icon || 'circle-info', size: 'xs', color: hasActive ? 'primary' : 'outline' }" />
        </div>

        <div class="selector__main" :class="{ [`druk-l-surface-${surface}`]: surface }">
            <input
                :value="value"
                ref="area"
                :name="name"
                :placeholder="placeholder"
                :data-vv-name="name"
                :data-vv-as="errorLabel || validatorLabel || label"
                v-validate="rules || ''"
                :list="list"
                :maxlength="maxlength"
                @focus="hasActive = true"
                @blur="hasActive = false"
                @keyup.enter="$emit('enter')"
                @keyup="$emit('keyup')"
                @input="onInput($event.target.value)"
                class="selector__area" />

            <div class="selector__buttons">
                <div
                    class="selector__button"
                    :class="{ 'druk-is-disabled': max && $fn.toFloat(value) >= $fn.toFloat(max) }"
                    @click="onUp">
                    <font-awesome-icon icon="fa-regular fa-plus" />
                </div>

                <div
                    class="selector__button"
                    :class="{ 'druk-is-disabled': min && $fn.toFloat(value) <= $fn.toFloat(min) }"
                    @click="onDown">
                    <font-awesome-icon icon="fa-regular fa-minus" />
                </div>
            </div>
        </div>

        <druk-hint v-if="hint && hint.isSide" class="selector__hint" :tooltip="tooltip" :icon="ttIcon" />

        <div v-if="errors.has(name)" class="selector__error">
            <span :class="{ [`druk-l-surface-${surface}`]: surface }">{{ errorText || errors.first(name) }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'form-input-num',

        inject: ['$validator'],

        props: {
            value: {
                required: true,
            },
            name: {
                type: String,
                required: true,
            },
            min: {
                default: 0,
                type: [String, Number],
            },

            max: [String, Number],
            placeholder: String,
            maxlength: String,
            label: String,
            validatorLabel: String,
            errorLabel: String,
            translate: String,
            rules: [String, Object],
            filterType: Boolean,
            errorText: String,

            hasError: {
                default: false,
                type: Boolean,
            },
            surface: {
                type: String,
                default: 'tint-pale',
            },

            list: String,
            hint: [String, Object],
            loader: Boolean,
        },

        data() {
            return {
                hasActive: false,
            };
        },

        watch: {
            max() {
                this.onFocusOut();
            },

            mix() {
                this.onFocusOut();
            },

            loader() {
                this.onRemoveFocus();
            },
        },

        computed: {
            tooltip() {
                if (!this.hint) return null;

                return {
                    text: this.hint.text || this.hint,
                    from: this.hint.from || 'top',
                    maxWidth: this.hint.maxWidth,
                    isNotCollapse: this.hint.isNotCollapse,
                };
            },

            ttIcon() {
                if (!this.hint?.icon) return { name: 'circle-info' };

                return typeof this.hint.icon === 'string' ? { name: this.hint.icon } : this.hint.icon;
            },

            hasFull() {
                return this.value !== null && this.value !== 'undefined';
            },

            isRequired() {
                if (this.rules && (typeof this.rules === 'string' ? this.rules.indexOf('required') !== -1 : this.rules.required))
                    return true;

                return false;
            },
        },

        methods: {
            onClear() {
                if (!this.hasFull) return;
                this.$emit('input', null);
            },

            onInput(val) {
                this.$emit('input', val);
            },

            onUp() {
                let value = $fn.toFloat(this.value) + 1;

                if (this.max && value > $fn.toFloat(this.max)) value = $fn.toFloat(this.max);
                this.returnValue(value);
            },

            onDown() {
                let decimal = $fn.toFloat(this.value).toString().split('.')[1]?.length || 0,
                    value = ($fn.toFloat(this.value) - 1).toFixed(decimal);

                if (this.min && value < $fn.toFloat(this.min)) val = $fn.toFloat(this.min);
                this.returnValue(value);
            },

            returnValue(value) {
                if (this.value?.toString().includes(',')) value = value.toString().replace('.', ',');
                this.$emit('input', value);

                this.$validator.validate(this.name);
            },

            onFocusOut() {
                this.hasActive = false;

                let value = $fn.toFloat(this.value);

                if (this.value < $fn.toFloat(this.min)) value = $fn.toFloat(this.min);
                if (this.value > $fn.toFloat(this.max)) value = $fn.toFloat(this.max);

                this.returnValue(value);
            },

            onRemoveFocus() {
                this.hasActive = false;
                this.$refs.area.blur();
            },

            onToggle() {
                this.$refs.area.focus();
            },
        },
    };
</script>

<style lang="scss" scoped></style>
