<template>
    <span class="imgPrev">
        <span class="imgPrev__title">{{ title }}</span>

        <span class="imgPrev__main">
            <span class="imgPrev__preview">
                <div class="imgPrev__preview-content" :class="{ [`druk-has-rotation-${rotation}`]: rotation }">
                    <druk-icon :name="'p'" :size="'full'" />
                </div>
            </span>
        </span>
    </span>
</template>

<script>
    export default {
        name: 'img-preview',

        props: {
            title: String,
            rotation: [Number, String],
        },
    };
</script>

<style lang="scss" scoped>
    .imgPrev {
        position: relative;
        &:hover {
            .imgPrev__main {
                opacity: 1;
            }
        }
        &__title {
            cursor: pointer;
        }
        &__main {
            pointer-events: none;
            position: absolute;
            width: 100px;
            height: 100px;
            opacity: 0;
            z-index: 3;
            border-radius: 12px;
            transition: all 0.15s ease-in-out;
            &.has-left {
                top: 50%;
                right: calc(100% + 8px);
                transform: translateY(-50%);
            }
            &.has-right {
                top: 50%;
                left: calc(100% + 8px);
                transform: translateY(-50%);
            }
            &.has-right-bottom {
                bottom: 100%;
                left: 100%;
                border-bottom-left-radius: 0px;
                transform: translateY(0%);
            }
        }
        &__preview {
            display: flex;
            width: 100%;
            height: 100%;
            background-color: var(--druk-tint-surface-container-brighter);
            border-radius: 12px;
            box-shadow: var(--druk-elevation-2);
            &-content {
                margin: auto;
                padding: var(--druk-gap-m);
                width: 100%;
                height: 100%;
                &.druk-has-rotation-1,
                &.druk-has-rotation-5 {
                    transform: rotate(0deg);
                }
                &.druk-has-rotation-2,
                &.druk-has-rotation-6 {
                    transform: rotate(180deg);
                }
                &.druk-has-rotation-3,
                &.druk-has-rotation-7 {
                    transform: rotate(90deg);
                }
                &.druk-has-rotation-4,
                &.druk-has-rotation-8 {
                    transform: rotate(270deg);
                }
            }
        }
    }
</style>
