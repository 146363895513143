import Vue from 'vue';

import axios from 'axios';

import calculation from './calculation/index';
import details from './details';
import prepress from './prepress';
import postpress from './postpress';
import algorithms from './algorithms';
import wizard from './wizard';

const CancelToken = axios.CancelToken;
let cancel;

const DEFAULT_PAYLOAD_DATA = {
    count: 1,
    width: null,
    height: null,
    label_width: null,
    label_height: null,
    padding: null,
    angle_corner_radius: null,
    indent_left: null,
    indent_right: null,
    core_diameter: null,
    roll_diameter: null,
    winding_direction: null,
    rotation: null,
    label_type: null,
    details: [],
    use_custom_roll_diameter: false,
};

export default {
    namespaced: true,

    modules: {
        calculation,
        details,
        prepress,
        postpress,
        algorithms,
        wizard,
    },

    state: {
        ALGORITHM_COMMON_DATA: {
            kindDetail: null,
            detailAlg: null,
            contractorAlg: null,
            previewAlg: null,
        },

        LOGS_COMPILANCE_METRICS: {
            time: 'metrics.seconds',
            extra_time: 'metrics.seconds',
            weight: 'metrics.gramms',
        },

        LOGS_RESULT_COMPILANCE_METRICS: {
            time: null,
            extra_time: null,
            weight: 'metrics.gramms',
        },

        LOGS_MAIN_OPERATIONS: {
            price: 'views.log.cost',
            product_kind_price: 'views.log.cost',
            time: 'product.calc.log',
            weight: 'views.log.weight',
            manufacturing_costs: 'orders.product_manufacturing_costs',
        },

        list: [],
        listPagination: {},

        rootList: [],

        item: {},
        softItem: {},
        rootItem: {},

        formData: {},
        algorithmFormData: {},

        calculationPayload: { ...DEFAULT_PAYLOAD_DATA },

        calculations: {},
        recommendations: {},
        preCalculation: {},
        logs: {},
        message: null,
        wordsMap: {},
        winners: {},

        modifyData: {},

        listFilter: {
            page: null,
            limit: null,
        },

        loadingGetList: false,
        loadingGetRooteList: false,
        loadingGetItem: false,
        loadingGetRootItem: false,
        loadingGetSoftItem: false,
        loadingGetPreCalculation: false,
        loadingSaveItem: false,
        loadingSortList: false,
        loadingDeleteItem: false,
    },

    getters: {
        listActiveFilter(state) {
            return Object.keys(state.listFilter)
                .filter((key) => state.listFilter[key] !== null)
                .reduce((resp, key) => ((resp[key] = state.listFilter[key]), resp), {});
        },

        sortedList(state) {
            return state.list.sort((item) => item.sort);
        },

        hasEmptyFormData(state) {
            return !Object.keys(state.formData).length;
        },
    },

    mutations: {
        SET_LIST(state, list) {
            state.list = list;
        },

        SET_ROOT_LIST(state, list) {
            state.rootList = list;
        },

        SET_ITEM(state, item) {
            state.item = item;
        },

        SET_ROOT_ITEM(state, item) {
            state.rootItem = item;
        },

        SET_CALC(state, calc) {
            state.calculations = calc;
        },

        SET_CALC_RECS(state, recommendations) {
            state.recommendations = recommendations;
        },

        SET_PRE_CALC(state, precalc) {
            state.preCalculation = precalc;
        },

        SET_LOGS(state, logs) {
            state.logs = logs;
        },

        SET_WINNERS(state, winners) {
            state.winners = winners;
        },

        SET_PAGINATION(state, pagination) {
            state.listPagination = pagination;
        },

        SET_FILTER_LIST(state, { key, val }) {
            state.listFilter[key] = val;
        },

        SET_CALC_MESSAGE(state, message) {
            state.message = message;
        },

        SET_SOFT_CALC(state, softItem) {
            state.softItem = softItem;
        },

        SET_PAYLOAD_DATA(state, { key, value }) {
            state.calculationPayload[key] = value;
        },

        SET_FORM_DATA(state, formData) {
            Vue.set(state, 'formData', formData);
        },

        SET_FORM_DATA_PROP(state, { key, value }) {
            Vue.set(state.formData, key, value);
        },

        SET_ALGORITHM_FORM_DATA(state, formData) {
            Vue.set(state, 'algorithmFormData', formData);
        },

        SET_ALGORITHM_FORM_DATA_PROP(state, { key, value }) {
            Vue.set(state.algorithmFormData, key, value);
        },

        SET_MODIFY_DATA(state, { key, value }) {
            Vue.set(state.modifyData, key, value);
        },

        SET_LOADING_STATUS(state, { value_key, status }) {
            state[value_key] = status;
        },

        UPDATE_PAYLOAD_DATA(state, formData) {
            Object.assign(state.calculationPayload, formData);
        },

        RESET_LIST(state) {
            state.list = [];
        },

        RESET_ITEM(state) {
            state.item = {};
        },

        RESET_FORM_DATA(state) {
            state.formData = {};
        },

        RESET_PAYLOAD_DATA(state) {
            state.calculationPayload = { ...DEFAULT_PAYLOAD_DATA };
        },

        RESET_CALC_RECS(state) {
            state.recommendations = {};
        },

        RESET_MODIFY_DATA(state) {
            state.modifyData = {};
        },

        RESET_CALCULATIONS(state) {
            state.calculations = {};
            state.recommendations = {};
            state.logs = {};
            state.winners = {};
            state.message = null;
        },
    },

    actions: {
        GET_LIST: async ({ state, getters, rootGetters, commit }, { productId, extraFilters = {} }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: true });

                let params = Object.keys(extraFilters).length
                    ? Object.assign(
                          { ...getters.listActiveFilter },
                          Object.fromEntries(Object.entries(extraFilters).filter(([key, value]) => value !== null)),
                      )
                    : getters.listActiveFilter;

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters[`currentTypography`].id}/products/${productId}/kinds`
                    : `/api/products/${productId}/kinds`;

                let resp = await $axios.get(path, { params });

                commit('SET_LIST', resp.list);
                commit('SET_PAGINATION', resp.pagination);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetList', status: false });
            }
        },

        GET_ROOT_LIST: async ({ state, getters, rootGetters, commit }, { productId }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetRootList', status: true });

                let resp = await $axios.get(`/api/products/${productId}/kinds`);

                commit('SET_ROOT_LIST', resp.list);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetRootList', status: false });
            }
        },

        GET_ITEM: async ({ state, getters, rootGetters, commit }, { productId, id }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters[`currentTypography`].id}/products/${productId}/kinds/${id}`
                    : `/api/products/${productId}/kinds/${id}`;

                let resp = await $axios.get(path, {
                    params: {
                        with_logs: 0,
                        with_calculation: 0,
                    },
                });

                commit('SET_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetItem', status: false });
            }
        },

        GET_ROOT_ITEM: async ({ state, getters, rootGetters, commit }, { productId, id }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetRootItem', status: true });

                let path = `/api/products/${productId}/kinds/${id}`;

                let resp = await $axios.get(path, {
                    params: {
                        with_logs: 0,
                        with_calculation: 0,
                    },
                });

                commit('SET_ROOT_ITEM', resp.single);

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingGetRootItem', status: false });
            }
        },

        SAVE_ITEM: async ({ state, rootGetters, commit, dispatch }, { productId, formData, without_fetch, without_noty }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters[`currentTypography`].id}/products/${productId}/kinds`
                    : `/api/products/${productId}/kinds`;

                let resp = await $axios.post(path, formData, {
                    headers: {
                        'Without-Noty': without_noty,
                    },
                });

                if (!without_fetch) {
                    dispatch('GET_LIST', { productId });

                    if (formData.id && Object.keys(state.item).length)
                        dispatch('GET_ITEM', {
                            productId: productId,
                            id: formData.id,
                        });
                }

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        COPY_ITEM: async ({ state, rootGetters, commit, dispatch }, { productId, id, without_fetch }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters[`currentTypography`].id}/products/${productId}/kinds/${id}/copy`
                    : `/api/products/${productId}/kinds/${id}/copy`;

                let resp = await $axios.post(path);

                if (!without_fetch) dispatch('GET_LIST', { productId });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingSaveItem', status: false });
            }
        },

        SORT_LIST: async ({ state, rootGetters, commit, dispatch }, { productId, formData, without_fetch }) => {
            try {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSortList',
                    status: true,
                });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters[`currentTypography`].id}/products/${productId}/kinds/sort`
                    : `/api/products/${productId}/kinds/sort`;

                let resp = await $axios.post(path, formData);

                if (!without_fetch) dispatch('GET_LIST', { productId });

                if (formData.id && Object.keys(state.item).length)
                    dispatch('GET_ITEM', {
                        productId: productId,
                        id: formData.id,
                    });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', {
                    value_key: 'loadingSortList',
                    status: false,
                });
            }
        },

        DELETE_ITEM: async ({ commit, rootGetters, dispatch }, { productId, id, without_fetch }) => {
            try {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: true });

                let path = rootGetters.hasTypographyAccess
                    ? `/api/typographies/${rootGetters[`currentTypography`].id}/products/${productId}/kinds/${id}`
                    : `/api/products/${productId}/kinds/${id}`;

                let resp = await $axios.delete(path);

                if (!without_fetch) dispatch('GET_LIST', { productId });

                return resp;
            } catch (e) {
                throw e;
            } finally {
                commit('SET_LOADING_STATUS', { value_key: 'loadingDeleteItem', status: false });
            }
        },
    },
};
