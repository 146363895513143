var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.stage)?_c('div',{staticClass:"wizard__header"},[_c('div',{staticClass:"wizard__current"},[_vm._v(_vm._s(_vm.stage.title))]),_c('div',{staticClass:"wizard__stages"},_vm._l((_vm.stages),function(item,index){return _c('div',{key:index,staticClass:"wizard__stage stage",class:{
                'is-current': item.value === _vm.stage.value,
                'is-done': item.is_done,
                'is-dirty': item.is_dirty,
                'is-missing': item.is_missing,
                'is-to-done': _vm.stages[index + 1] && _vm.stages[index + 1].is_done,
                'is-to-current': _vm.stages[index + 1] && _vm.stages[index + 1].value === _vm.stage.value,
                'is-to-dirty': _vm.stages[index + 1] && _vm.stages[index + 1].is_dirty,
            }},[_c('div',{staticClass:"stage__body"},[_c('div',{staticClass:"stage__cnt",on:{"click":function($event){return _vm.onStage(item)}}},[_c('div',{staticClass:"stage__value"},[_c('span',[(item.is_broken)?_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-circle-xmark"}}):(item.is_done)?_c('font-awesome-icon',{attrs:{"icon":"fa-regular fa-circle-check"}}):_c('label',[_vm._v(_vm._s(index + 1))])],1)])]),_c('div',{staticClass:"stage__title"},[_vm._v(_vm._s(item.title))]),(index !== _vm.stages.length - 1)?_c('div',{staticClass:"stage__divider",style:({ '--druk-divider-area': `${_vm.dividerWidth}px` })}):_vm._e()])])}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }