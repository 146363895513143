import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import constant from '@/store/constant';
import permissions from '@/store/permissions';
import auth from '@/store/auth';
import currency from '@/store/currency';

import dashboard from './modules/dashboard/index.js';
import clients from './modules/contacts/clients/index.js';
import companies from './modules/contacts/companies/index.js';
import employees from './modules/employees/index.js';
import typographies from './modules/typographies/index.js';
import pages from './modules/pages';
import partnerPages from './modules/partnerPages/index.js';
import translates from './modules/translates';
import blogs from './modules/blogs';
import seo from './modules/seo';
import partners from './modules/partners';
import mailTemplates from './modules/mailTemplates';
import feedback from './modules/feedback';
import basket from './modules/basket';
import files from './modules/files';
import stock from './modules/stock/index.js';
import delivery from './modules/delivery/index.js';

// @Explanation: Orders
import orders from './modules/orders/index.js';
import orderCreation from './modules/orderCreation/index.js';

// @Explanation: Balance
import balance from './modules/balance/index.js';
import transactions from './modules/balance/transactions';
import accounts from './modules/balance/accounts';

// @Explanation: Operations
import operations from './modules/operations/index.js';
import prepress from './modules/operations/prepress/index.js';
import print from './modules/operations/print/index.js';
import postpress from './modules/operations/postpress/index.js';
import logistic from './modules/operations/logistic/index.js';

// @Explanation: Categories
import categories from './modules/categories/index.js';

// @Explanation: Products
import products from './modules/products/index.js';
import kinds from './modules/products/kinds/index.js';

// @Explanation: Library
import works from './modules/library/works/index.js';
import materials from './modules/library/materials/index.js';
import equipments from './modules/library/equipments/index.js';
import formats from './modules/library/formats/index.js';
import contractors from './modules/library/contractors/index.js';
import detailsLib from './modules/library/detailsLib/index.js';
import algorithms from './modules/library/algorithms/index.js';
import shapes from './modules/library/configs/shapes.js';

// @Explanation: Roles
import roles from './modules/technicalData/roles/index.js';

// @Explanation: TechnicalData
import languages from './modules/technicalData/languages';
import translateScopes from './modules/technicalData/translateScopes';
import settings from './modules/technicalData/settings';
import urgency from './modules/technicalData/urgency';
import calcModes from './modules/technicalData/calcModes';
import params from './modules/technicalData/params';
import pricing from './modules/technicalData/pricing/index';

// @Explanation: Knowledge
import knowledge from './modules/knowledge/index.js';

export default new Vuex.Store({
    modules: {
        constant,
        permissions,
        auth,
        currency,

        dashboard,
        clients,
        companies,
        employees,
        typographies,
        categories,
        pages,
        translates,
        partnerPages,
        blogs,
        seo,
        partners,
        mailTemplates,
        feedback,
        basket,
        files,
        stock,
        delivery,

        // @Explanation: Orders
        orders,
        orderCreation,

        // @Explanation: Balance
        balance,
        transactions,
        accounts,

        // @Explanation: Operations
        operations,
        prepress,
        print,
        postpress,
        logistic,

        // @Explanation: Products
        products,
        kinds,

        // @Explanation: Library
        works,
        materials,
        equipments,
        formats,
        contractors,
        detailsLib,
        algorithms,
        shapes,

        // @Explanation: Roles
        roles,

        // @Explanation: TechnicalData
        languages,
        translateScopes,
        settings,
        urgency,
        calcModes,
        params,
        pricing,

        // @Explanation: Knowledge
        knowledge,
    },

    state: {
        KEY_LAYOUT_DEFAULT: 'default-layout',
        KEY_LAYOUT_LOGIN: 'login-layout',
        KEY_LAYOUT_ERROR: 'error-layout',
        KEY_LAYOUT_IFRAME: 'iframe-layout',

        DEFAULT_LANG: 'uk',

        VERIFY_ERROR: false,
        VERIFY_ERROR_MESSAGE: null,

        DEFAULT_CURRENCY: 'EUR',
        PLN_CODE: 'PLN',
        PLN_ALT_CODE: 'zł',
        ALLOW_ORIGIN: '*',
        ALLOW_CREDENTIAL: true,
        ALLOW_METHODS: 'OPTIONS, GET, POST',

        BASE_COLOR: '#1EABF1',

        THEME_LIGHT: 'druk-light',
        THEME_DARK: 'druk-dark',

        LG_SCREEN_WIDTH: 1280,
        DEF_SCREEN_WIDTH: 1024,
        MD_SCREEN_WIDTH: 768,
        SM_SCREEN_WIDTH: 480,

        selectedCurrency: localStorage.getItem('_currency_druk_crm') || null,
        selectedCurrencyCode: localStorage.getItem('_currency_druk_crm') || null,

        layout: 'default-layout',

        selectedLang: null,
        selectedClientLang: null,

        windowWidth: null,

        theme: JSON.parse(localStorage.getItem('_druk_engine_theme')) || 'druk-light',
        hasInverseDrawer: JSON.parse(localStorage.getItem('_druk_engine_theme_inverse_drawer')) || false,
        hasAlternativeHeaderLogo: JSON.parse(localStorage.getItem('_druk_engine_theme_alternative_header_logo')) || false,

        hasActiveDrawer: true,
        hasActiveSideSheet: false,
    },

    getters: {
        isDarkTheme(state) {
            return state.theme === state.THEME_DARK;
        },

        isSMMinWidth(state) {
            return state.windowWidth > state.SM_SCREEN_WIDTH;
        },

        isMDMinWidth(state) {
            return state.windowWidth > state.MD_SCREEN_WIDTH;
        },

        isDEFMinWidth(state) {
            return state.windowWidth > state.DEF_SCREEN_WIDTH;
        },

        isLGMinWidth(state) {
            return state.windowWidth > state.LG_SCREEN_WIDTH;
        },

        isSMMaxWidth(state) {
            return state.windowWidth <= state.SM_SCREEN_WIDTH;
        },

        isMDMaxWidth(state) {
            return state.windowWidth <= state.MD_SCREEN_WIDTH;
        },

        isDEFMaxWidth(state) {
            return state.windowWidth <= state.DEF_SCREEN_WIDTH;
        },

        isLGMaxWidth(state) {
            return state.windowWidth <= state.LG_SCREEN_WIDTH;
        },
    },

    mutations: {
        SET_LAYOUT(state, payload) {
            state.layout = payload;
        },

        SET_DRAWER_STATUS(state, status) {
            state.hasActiveDrawer = status;
        },

        SET_SELECTED_LANG(state, lang) {
            state.selectedLang = lang;
        },

        SET_SELECTED_CLIENT_LANG(state, lang) {
            state.selectedClientLang = lang;
        },

        SET_SELECTED_CURRENCY(state, selectedCurrency) {
            $axios.defaults.headers.common['Currency-Code'] = selectedCurrency;
            state.selectedCurrency = selectedCurrency;
            state.selectedCurrencyCode = selectedCurrency === state.PLN_CODE ? state.PLN_ALT_CODE : selectedCurrency;
            localStorage.setItem('_currency_druk_crm', selectedCurrency);
        },

        SET_WINDOW_WIDTH(state, width) {
            state.windowWidth = width;
        },

        SET_PROJECT_THEME(state, theme) {
            state.theme = theme;
            localStorage.setItem('_druk_engine_theme', JSON.stringify(theme));
            document.documentElement.setAttribute('data-theme', theme);
        },

        SET_PROJECT_INVERSE_DRAWER(state, hasInverseDrawer) {
            state.hasInverseDrawer = hasInverseDrawer;
            localStorage.setItem('_druk_engine_theme_inverse_drawer', JSON.stringify(hasInverseDrawer));
        },

        SET_PROJECT_ALTERNATIVE_LOGO(state, hasAlternativeHeaderLogo) {
            state.hasAlternativeHeaderLogo = hasAlternativeHeaderLogo;
            localStorage.setItem('_druk_engine_theme_alternative_header_logo', JSON.stringify(hasAlternativeHeaderLogo));
        },

        SHOW_SIDE_SHEET(state) {
            state.hasActiveSideSheet = true;
        },

        HIDE_SIDE_SHEET(state) {
            state.hasActiveSideSheet = false;
        },
    },
});
