<template>
    <div class="druk-l-support" :class="{ 'druk-is-block': isBlock }">
        <div class="druk-l-support__wrapper">
            <div
                class="druk-l-support__text"
                :class="{
                    'druk-is-success': isSuccess && isAlternative,
                    'druk-is-warning': isWarning && isAlternative,
                    'druk-is-error': isError && isAlternative,
                    'druk-is-inverse': isInverse,
                    'druk-is-truncated': isTruncated,
                    'druk-has-no-media': hasNoMedia,
                }"
                :style="{ '--max-width': truncate }">
                <div v-if="!isAlternative && !hasBeforeLoader" class="druk-l-support__icon">
                    <druk-icon v-bind="iconConfiguration" />
                </div>

                <div v-if="hasBeforeLoader" class="druk-l-support__loader">
                    <druk-loader :color="'outline'" :size="'xs'" :isIcon="true" />
                </div>

                <slot></slot>
            </div>

            <div v-if="hasAfterLoader" class="druk-l-support__loader druk-is-after">
                <druk-loader :color="'outline'" :size="'xs'" :isIcon="true" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'druk-support',

        props: {
            text: String,
            truncate: String,

            isSuccess: [Boolean, Number],
            isWarning: [Boolean, Number],
            isError: [Boolean, Number],

            isBlock: Boolean,
            isInverse: Boolean,
            isTextField: Boolean,

            hasBeforeLoader: Boolean,
            hasAfterLoader: Boolean,
            hasNoIcon: Boolean,
            hasNoMedia: Boolean,
        },

        computed: {
            icons() {
                return {
                    'circle-check': this.isSuccess,
                    'triangle-exclamation': this.isWarning,
                    'circle-exclamation': this.isError,
                    'circle-info': this.isDefault,
                };
            },

            colors() {
                return {
                    success: this.isSuccess,
                    warning: this.isWarning,
                    error: this.isError,
                    outline: this.isDefault,
                };
            },

            iconConfiguration() {
                return {
                    name: Object.keys(this.icons).find((key) => this.icons[key]),
                    color: Object.keys(this.colors).find((key) => this.colors[key]),
                    size: 'xs',
                    isInline: true,
                };
            },

            isDefault() {
                return !(this.isSuccess || this.isWarning || this.isError);
            },

            isTruncated() {
                return !!this.truncate;
            },

            isAlternative() {
                return this.hasNoIcon || this.isTextField;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-l-support {
        display: inline-block;
        line-height: 1;
        &__wrapper {
            display: inline-flex;
            line-height: 1;
        }
        &.druk-is-block {
            display: block;
        }
        &__text {
            display: inline-block;
            word-break: break-word;
            text-transform: none;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.5px;
            font-weight: 500;
            color: var(--druk-on-surface-variant);
            &.druk-is-inverse {
                color: var(--druk-inverse-on-surface-variant);
            }
            &.druk-is-truncated {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: var(--max-width);
            }
            &.druk-has-no-media::v-deep {
                img,
                video,
                audio,
                source,
                track,
                embed,
                iframe,
                svg,
                canvas,
                area,
                map,
                figure,
                figcaption,
                .media,
                .image {
                    display: none;
                }
            }
        }
        &__icon {
            display: inline-flex;
            vertical-align: text-top;
            margin: -1px var(--druk-gap-step) -1px var(--druk-n-gap-step);
        }
        &__loader {
            height: 16px;
            display: inline-flex;
            vertical-align: middle;
            margin: 0 var(--druk-gap-step) 0 0;
            &.druk-is-after {
                margin: 0 0 0 var(--druk-gap-step);
            }
        }
    }
</style>
