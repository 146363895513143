<template>
    <div v-if="stage" class="wizard__header">
        <div class="wizard__current">{{ stage.title }}</div>

        <div class="wizard__stages">
            <div
                v-for="(item, index) in stages"
                :key="index"
                class="wizard__stage stage"
                :class="{
                    'is-current': item.value === stage.value,
                    'is-done': item.is_done,
                    'is-dirty': item.is_dirty,
                    'is-missing': item.is_missing,
                    'is-to-done': stages[index + 1] && stages[index + 1].is_done,
                    'is-to-current': stages[index + 1] && stages[index + 1].value === stage.value,
                    'is-to-dirty': stages[index + 1] && stages[index + 1].is_dirty,
                }">
                <div class="stage__body">
                    <div class="stage__cnt" @click="onStage(item)">
                        <div class="stage__value">
                            <span>
                                <font-awesome-icon v-if="item.is_broken" icon="fa-regular fa-circle-xmark" />
                                <font-awesome-icon v-else-if="item.is_done" icon="fa-regular fa-circle-check" />
                                <label v-else>{{ index + 1 }}</label>
                            </span>
                        </div>
                    </div>

                    <div class="stage__title">{{ item.title }}</div>

                    <div
                        class="stage__divider"
                        v-if="index !== stages.length - 1"
                        :style="{ '--druk-divider-area': `${dividerWidth}px` }"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'wizard-header',

        props: {
            stage: {
                type: Object,
                default: () => ({}),
            },

            stages: Array,
        },

        data() {
            return {
                STAGE_ITEM_WIDTH: 36,

                dividerWidth: null,
            };
        },

        mounted() {
            this.setDividerWidth();
        },

        created() {
            this.$bus.$on('update-wizard-styles', (delay) => this.setDividerWidth(delay));
            window.addEventListener('resize', this.setDividerWidth);
        },

        destroyed() {
            this.$bus.$off('update-wizard-styles');
            window.removeEventListener('resize', this.setDividerWidth);
        },

        methods: {
            setDividerWidth(delay) {
                let timer = setTimeout(() => {
                    clearTimeout(timer);

                    let body = document.querySelector('.wizard__stages'),
                        stages = [...document.querySelectorAll('.stage__value')];

                    if (!body) return;
                    this.dividerWidth = (body.clientWidth - this.STAGE_ITEM_WIDTH * stages.length) / (stages.length - 1);
                }, delay || 0);
            },

            onStage(stage) {
                if (this.$route.meta.stage == stage.value) return;

                this.$emit('onStage', stage);

                if (stage.route) {
                    let matched = [...this.$route.matched].shift().name,
                        parent = this.$router.options.routes.find((item) => item.name === matched),
                        stageRoute = parent.children.find((item) => item.name === parent.meta.nested[stage.value]);

                    this.$router.push({
                        name: stageRoute.name,
                        params: this.$route.params,
                        query: this.$route.query,
                    });
                }
            },
        },
    };
</script>

<style lang="scss" scoped></style>
