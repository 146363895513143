<template>
    <div class="druk-c-crcl-ind">
        <div class="druk-c-crcl-ind__wrapper" :class="{ [`druk-u-size-${size}`]: size }">
            <svg class="druk-c-crcl-ind__progress" :style="{ stroke: $material[color] }">
                <circle
                    class="druk-c-crcl-ind__path"
                    :cx="variables[size].cx"
                    :cy="variables[size].cy"
                    :r="variables[size].r"
                    fill="none"
                    :stroke-width="variables[size].strokeWidth"
                    stroke-miterlimit="10"></circle>
            </svg>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'druk-circular-indicator',

        props: {
            size: {
                type: String,
                default: 'xs', // @Explanation: Possible values - xs, s, m, l
            },
            color: {
                type: String,
                default: 'on-primary-container', // @Explanation: Possible values - on-primary-container, on-secondary-container, any system colors used on surfaces, does not matter
            },
        },

        computed: {
            variables() {
                return {
                    xs: {
                        cx: 9,
                        cy: 9,
                        r: 7,
                        strokeWidth: 2,
                    },
                    s: {
                        cx: 12,
                        cy: 12,
                        r: 10,
                        strokeWidth: 2,
                    },
                    m: {
                        cx: 20,
                        cy: 20,
                        r: 16,
                        strokeWidth: 4,
                    },
                    l: {
                        cx: 24,
                        cy: 24,
                        r: 20,
                        strokeWidth: 4,
                    },
                };
            },
        },
    };
</script>

<style lang="scss" scoped>
    .druk-c-crcl-ind {
        display: block;
        &__wrapper {
            --stroke-dasharray-0: 1, 100;
            --stroke-dasharray-50: 24, 100;
            --stroke-dasharray-100: 36, 100;

            --stroke-dashoffset-0: 0;
            --stroke-dashoffset-50: -18;
            --stroke-dashoffset-100: -62;

            &.druk-u-size-xs {
                --stroke-dasharray-50: 12, 100;
                --stroke-dasharray-100: 18, 100;
                --stroke-dashoffset-100: -42;

                width: 18px;
                height: 18px;
            }
            &.druk-u-size-s {
                width: 24px;
                height: 24px;
            }
            &.druk-u-size-m {
                --stroke-dasharray-50: 42, 100;
                --stroke-dasharray-100: 46, 100;
                --stroke-dashoffset-100: -92;

                width: 40px;
                height: 40px;
            }
            &.druk-u-size-l {
                --stroke-dasharray-0: 2, 200;
                --stroke-dasharray-50: 45, 200;
                --stroke-dasharray-100: 89, 200;

                --stroke-dashoffset-50: -48;
                --stroke-dashoffset-100: -124;

                width: 48px;
                height: 48px;
            }
        }
        &__progress {
            display: flex;
            position: relative;
            width: 100%;
            height: 100%;
            animation: rotate 2s linear infinite;
        }
        &__path {
            stroke-dasharray: 1, 100;
            stroke-dashoffset: 0;
            stroke-linecap: round;
            animation: dash 1.2s linear infinite;
        }

        @-webkit-keyframes rotate {
            100% {
                transform: rotate(360deg);
            }
        }

        @-moz-keyframes rotate {
            100% {
                transform: rotate(360deg);
            }
        }

        @keyframes rotate {
            100% {
                transform: rotate(360deg);
            }
        }

        @-webkit-keyframes dash {
            0% {
                stroke-dasharray: var(--stroke-dasharray-0);
                stroke-dashoffset: var(--stroke-dashoffset-0);
            }
            50% {
                stroke-dasharray: var(--stroke-dasharray-50);
                stroke-dashoffset: var(--stroke-dashoffset-50);
            }
            100% {
                stroke-dasharray: var(--stroke-dasharray-100);
                stroke-dashoffset: var(--stroke-dashoffset-100);
            }
        }

        @-moz-keyframes dash {
            0% {
                stroke-dasharray: var(--stroke-dasharray-0);
                stroke-dashoffset: var(--stroke-dashoffset-0);
            }
            50% {
                stroke-dasharray: var(--stroke-dasharray-50);
                stroke-dashoffset: var(--stroke-dashoffset-50);
            }
            100% {
                stroke-dasharray: var(--stroke-dasharray-100);
                stroke-dashoffset: var(--stroke-dashoffset-100);
            }
        }

        @keyframes dash {
            0% {
                stroke-dasharray: var(--stroke-dasharray-0);
                stroke-dashoffset: var(--stroke-dashoffset-0);
            }
            50% {
                stroke-dasharray: var(--stroke-dasharray-50);
                stroke-dashoffset: var(--stroke-dashoffset-50);
            }
            100% {
                stroke-dasharray: var(--stroke-dasharray-100);
                stroke-dashoffset: var(--stroke-dashoffset-100);
            }
        }
    }
</style>
